import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from '@mui/material';

const AppSelectBox = ({
  name,
  data,
  valueKey,
  labelKey,
  label,
  value,
  variant,
  onChange,
  className,
  helperText,
}) => {
  const items = data.map((item, index) => (
    <MenuItem key={index} value={valueKey ? item[valueKey] : item}>
      {item[labelKey]}
    </MenuItem>
  ));

  return (
    <FormControl variant={variant} fullWidth className={className}>
      {label && <InputLabel id={`${name}-label`}>{label}</InputLabel>}
      <Select
        id={name}
        labelId={`${name}-label`}
        value={value}
        onChange={onChange}
        label={label}
        error={helperText}
      >
        {items}
      </Select>
      {helperText && (
        <FormHelperText error={helperText}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default AppSelectBox;
