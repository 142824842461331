import {
  SET_USER_INFORMATION,
  REMOVE_USER_INFORMATION,
} from '../../utils/constants/constant';

const INIT_STATE = {
  authUserInformation: {},
};

const reducerFunc = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USER_INFORMATION: {
      return {
        ...state,
        authUserInformation: action.payload,
      };
    }
    case REMOVE_USER_INFORMATION: {
      return {
        ...INIT_STATE,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducerFunc;
