/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useDropzone } from 'react-dropzone';

import { makeStyles } from '@mui/styles';
import { DeleteOutline } from '@mui/icons-material';
import { AttachmentOutlined } from '@mui/icons-material';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    minHeight: props?.isMinHeight ? '55px' : '99px',
    border: '2px dashed #d8d8d8',
    borderRadius: 4,
    backgroundColor: '#fafafa',
    color: '#231f20',
    outline: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'border .24s ease-in-out',
    '&:hover': {
      borderColor: '#56B26C',
      cursor: 'pointer',
    },
    ...(props.root || {}),
  }),
  active: (props) => ({
    borderColor: '#2196f3',
    ...(props.active || {}),
  }),
  accept: (props) => ({
    borderColor: '#00e676',
    ...(props.accept || {}),
  }),
  reject: (props) => ({
    borderColor: '#ff1744',
    ...(props.accept || {}),
  }),
  previewImage: {
    display: 'block',
    width: 'auto',
    height: '100%',
    objectFit: 'contain',
  },
  margin02:{
    margin:'2px 0'
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    opacity: '1',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    borderRadius: '4px',
    '&:hover': {
      opacity: '0.3',
    },
  },
  thumbParent: {
    position: 'relative',
    marginRight: 25,
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    marginTop: 30,
    marginBottom: 8,
    marginRight: 0,
    width: 80,
    height: 80,
    padding: 0,
    boxSizing: 'border-box',
    position: 'relative',
    cursor: 'pointer',
    boxShadow: 'rgb(0 0 0 / 12%) 0 1px 6px, rgb(0 0 0 / 12%) 0 1px 4px',
    transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    marginLeft: 25,
    '&:nth-child(1)': {
      marginLeft: 0,
    },
    '&:hover': {
      '& $trashIcon': {
        opacity: '1',
      },
    },
  },
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  trashIcon: {
    top: '-18px',
    right: '-18px',
    width: '40px',
    height: '40px',
    opacity: '0',
    position: 'absolute',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: '0',
    fontSize: '0.875rem',
    minWidth: '0',
    boxShadow:
      '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
    boxSizing: 'border-box',
    minHeight: '36px',
    fontFamily: 'MaisonNeue',
    fontWeight: '500',
    lineHeight: '1.75',
    borderRadius: '50%',
    textTransform: 'uppercase',
    backgroundColor: '#e0e0e0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '.5s ease',
  },
  attachmentIcon: {
    height: 22,
    width: 22,
  },
  fileName: {
    width: '100%',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '5px',
    margin: '5px 0',
    color: '#656565',
  },
  fileContent: {
    width: '100%',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '5px',
    transition: '0.4s',
    '&:hover': {
      backgroundColor: '#f5f5f5',
      '& $removeIcon': {
        opacity: '1',
      },
    },
  },
  removeIcon: {
    fontSize: 16,
    cursor: 'pointer',
    color: '#656565',
    opacity: '0',
    transform: 'translateY(3px)',
    transition: '0.4s',
  },
  textCenter: {
    textAlign: 'center',
  },
}));
const DropzoneComponent = ({
  files,
  onDropFile,
  name,
  accept,
  onRemove,
  maxFiles = 1,
  sx,
  isMinHeight,
}) => {
  const classes = useStyles({ isMinHeight });
  const onDrop = (acceptedFiles) => {
    onDropFile(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: accept
      ? accept
      : {
          'image/*': [],
          'application/pdf': ['.pdf'],
          'application/msword': ['.doc', '.docx'],
          'application/vnd.ms-excel': ['.xls', '.xlsx'],
        },
    maxFiles,
  });

  const rootClasses = [
    classes.root,
    isDragActive ? classes.active : '',
    isDragAccept ? classes.accept : '',
    isDragReject ? classes.reject : '',
  ]
    .join(' ')
    .trim();
  const thumbs = files.map((file, index) => (
    <>
      <div key={file.name}>
        {accept && accept !== 'image/*' ? (
          <div className={classes.fileContent}>
            <div className={classes.fileName}>
              <AttachmentOutlined className={classes.attachmentIcon} />{' '}
              <div>
                {
                  file?.fileName?.split('/')[
                    file?.fileName?.split('/')?.length - 1
                  ]
                }
              </div>
              <div>
                <DeleteOutline
                  onClick={() => onRemove(index)}
                  className={classes.removeIcon}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.thumbParent}>
            <div className={classes.thumb}>
              <div className={classes.thumbInner}>
                <img src={file.preview} className={classes.previewImage} />
              </div>
              <div className={classes.trashIcon}>
                <DeleteOutline onClick={() => onRemove(index)} />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  ));
  return (
    <section>
      <div {...getRootProps({})} className={rootClasses}>
        <input {...getInputProps()} name={name} />
        <div className={classes.textCenter}>
          Drag and drop a file here or click to search
         <p className={classes.margin02}> Maximum file upload size is 2 MB</p>
        </div>
      </div>
      {files?.length ? (
        <aside className={accept ? '' : classes.thumbsContainer}>
          {thumbs}
        </aside>
      ) : (
        ''
      )}
    </section>
  );
};

export default DropzoneComponent;
