import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import JumboButton from '@jumbo/components/JumboButton/JumboButton';

const FormDialog = ({
  title,
  open,
  handleClose,
  children,
  confirmButtonText,
  cancelButtonText,
  maxWidth,
  handleSubmit,
}) => {
  return (
    <div>
      <Dialog fullWidth maxWidth={maxWidth} open={open} onClose={handleClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <JumboButton color="error" variant="contained" onClick={handleClose}>
            {cancelButtonText}
          </JumboButton>
          <JumboButton
            color="primary"
            variant="contained"
            onClick={handleSubmit}
          >
            {confirmButtonText}
          </JumboButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default FormDialog;
