import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@mui/styles';
import { ROUTES } from 'app/utils/constants/routes';
import { getTitle } from 'app/utils/appHelpers';
import { useIsMobileDevice } from 'app/hooks/isMobile';
import { getFeedbackList, createFeedback } from 'app/redux/actions/feedback';
import { Grid, Typography } from '@mui/material';
import { getStep, listStage } from '@jumbo/utils/stage';
import Card from '@jumbo/components/common/Card';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import StepperComponent from '@jumbo/components/common/FeedbackStepper';
import Div from '@jumbo/shared/Div/Div';
import JumboButton from '@jumbo/components/JumboButton/JumboButton';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: 24,
    '&:last-child': {
      paddingBottom: '18px',
    },
  },
  marginTop: {
    marginTop: 30,
  },
  buttonAction: {
    display: 'flex',
    marginLeft: '12px',
    paddingLeft: '20px',
    paddingRight: '8px',
    marginTop: '30px',
  },
}));

const Feedback = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { isMobile } = useIsMobileDevice();
  const { projectId } = useSelector(({ project }) => project);
  const { authUserInformation } = useSelector(
    ({ userInformation }) => userInformation,
  );
  const stage = listStage(authUserInformation?.projectProgress);
  const [feedbackData, setFeedbackData] = useState([]);
  const [step, setStep] = useState(
    getStep(authUserInformation?.projectProgress),
  );
  const [isEditAllow, setIsEditAllow] = useState(false);
  const [errorText, setErrorText] = useState([]);
  const handleSubmit = async () => {
    let validateRecord = validate();
    if (!validateRecord) {
      const requestData = [];
      // eslint-disable-next-line array-callback-return
      feedbackData.map((val) => {
        val.data.map((record) =>
          requestData.push({
            questionId: record?.questionId,
            projectId,
            stage: step,
            rating: record?.answer?.rating,
            remark: record?.answer?.remark,
          }),
        );
      });
      const response = await dispatch(
        createFeedback({ feedback: requestData }),
      );
      if (response.status === 200) {
        enqueueSnackbar(response.data.responseMessage, {
          variant: 'success',
        });
        setIsEditAllow(!isEditAllow);
      } else {
        enqueueSnackbar(response.error, {
          variant: 'error',
        });
      }
    }
  };
  const handleChange = (event, index, i) => {
    const { value } = event.target;
    const ratingData = [...feedbackData];
    ratingData[index].data[i].answer.remark = value;
    setFeedbackData([...ratingData]);
  };
  const handleChangeRating = (value, index, i) => {
    const ratingData = [...feedbackData];
    ratingData[index].data[i].answer.rating = value;
    setFeedbackData([...ratingData]);
  };
  const getFeedback = useCallback(async () => {
    if (step) {
      const response = await dispatch(
        getFeedbackList({ projectId, stage: step,forClient :true }),
      );
      if (response.status === 200) {
        const { feedbackList, isEditable } = response.data.data;
        if (feedbackList?.length) {
          feedbackList.forEach((singleFeedback) => {
            const { title, data } = singleFeedback;
            const feedbacknew = [];

            data.forEach((val) => {
              feedbacknew.push({
                question: val.question.question,
                questionId: val.question._id,
                answer: val?.answer ? val.answer : { rating: 0, remark: '' },
              });
            });
            feedbackData.push({
              title,
              data: feedbacknew,
            });
          });
          setFeedbackData([...feedbackData]);
          setIsEditAllow(isEditable);
        }
      }
    }
  }, [dispatch, projectId, step]);
  const handleClick = (id) => {
    if (id !== step) {
      setFeedbackData([]);
      setErrorText([]);
      setStep(id);
    }
  };
  useEffect(() => {
    getFeedback();
  }, [getFeedback]);
  const validate = () => {
    let feedbackError = [];
    let isError = false;
    let ratingError = false;
    if (feedbackData?.length) {
      feedbackData.forEach((val, index) => {
        const errors = {};
        const arr = [];
        val.data.forEach((record, index) => {
          const error = {};
          if (
            !record?.answer?.remark &&
            (val?.title === 'Note' ||
              (record?.answer?.rating <= 3 && record?.answer?.rating > 0))
          ) {
            error.remark = 'Please enter remark.';
            isError = true;
          }
          if (
            val?.title !== 'Note' &&
            (record?.answer?.rating === 0 || record?.answer?.rating === null)
          ) {
            ratingError = true;
            isError = true;
          }
          arr.push(error);
        });
        errors.data = arr;
        feedbackError.push(errors);
      });
      if (ratingError) {
        enqueueSnackbar('Please choose a star.', {
          variant: 'error',
        });
      }
      setErrorText(feedbackError);
    }
    return isError;
  };
  return (
    <>
      {isMobile ? <PageHeader title={getTitle(ROUTES.FEEDBACK)} /> : ''}
      <Card title="Active Feedback Forms" className={classes.cardContent}>
        <Typography variant="h6">
          We will be asking for your valuable feedback at 4 stages during the
          project execution. Kindly, rate the service received on a scale of 1
          to 5 - 1 being poor and 5 being excellent.
        </Typography>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={{ xs: 1 }}
          className={classes.marginTop}
        >
          {stage?.map((singleButton) => {
            return (
              <Grid key={singleButton?.id} item xs="auto">
                <JumboButton
                  onClick={() => handleClick(singleButton?.id)}
                  color="search"
                  variant={step === singleButton?.id ? 'contained' : 'outlined'}
                  size="small"
                >
                  {singleButton?.name}
                </JumboButton>
              </Grid>
            );
          })}
        </Grid>
        <Div className={classes.marginTop}>
          <StepperComponent
            orientation="vertical"
            data={feedbackData}
            active={true}
            handleChange={handleChange}
            handleChangeRating={handleChangeRating}
            errorText={errorText}
            isEditAllow={isEditAllow}
          />
        </Div>
        {isEditAllow && (
          <Div className={classes.buttonAction}>
            <JumboButton
              variant="contained"
              size="medium"
              onClick={() => handleSubmit()}
              disabled={!isEditAllow}
            >
              Submit
            </JumboButton>
          </Div>
        )}
      </Card>
    </>
  );
};
export default Feedback;
