import React from 'react';
import { Bar } from 'react-chartjs-2';

export const BarChart = ({ chartData, options }) => {
  return (
    <div className="chart-container">
      <Bar data={chartData} height={'150px'} options={options} />
    </div>
  );
};
export default BarChart;
