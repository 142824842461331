import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Grid,
  Typography,
  Box,
  Dialog,
  CardContent,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { getAssetPath } from 'app/utils/appHelpers';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ROUTES } from 'app/utils/constants/routes';
import { getTitle } from 'app/utils/appHelpers';
import { useIsMobileDevice } from 'app/hooks/isMobile';
import { getPhase } from 'app/redux/actions/phase';
import {
  getWorkScheduleTotalProgress,
  getWorkScheduleChartDataByPhase,
  getWorkScheduleProgressByPhase,
} from 'app/redux/actions/workschedule';
import { downloadChart } from '@jumbo/utils/downloadChart';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import Div from '@jumbo/shared/Div/Div';
import LinearProgress from '@jumbo/components/common/LinearProgress';
import Card from '@jumbo/components/common/Card';
import Grantt from '@jumbo/components/Charts/Gantt';
import { chartDataCalculation } from '@jumbo/components/Charts/Gantt/data';
import AppSelectBox from '@jumbo/components/JumboAppSelect';
import LinearProgressComponent from '@jumbo/components/common/LinearProgress';
import JumboAppTextField from '@jumbo/components/JumboAppTextField/JumboAppTextField';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import NoDataPlaceholder from '@jumbo/components/common/NoDataFound';

const useStyles = makeStyles((theme) => ({
  workScheduleImage: {
    position: 'relative',
    background: `url(${getAssetPath(
      `${ASSET_IMAGES}/work_schedule.png`,
    )}) no-repeat center`,
    backgroundSize: 'cover',
    color: theme.palette.text.white,
    borderRadius: 10,
    padding: 20,
  },
  linearProgress: {
    width: '97%',
    borderRadius: 4,
    height: 20,
    position: 'absolute',
    top: '75%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      width: '95%',
    },
    [theme.breakpoints.down('md')]: {
      width: '90%',
    },
  },
  marginBottom: {
    marginBottom: 30,
  },
  marginTop: {
    marginTop: 30,
  },
  marginCard: {
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
  },
  cardContent: {
    padding: 24,
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
  },
  iconBackGround: {
    backgroundColor: theme.palette.search.main,
    '&:hover': {
      backgroundColor: theme.palette.search.main,
    },
  },
  textAlignEnd: {
    textAlign: 'end',
  },
  marginTop17: {
    marginTop: 17,
  },
  linerProgressTextField: {
    width: '85%',
    marginLeft: '14px',
    [theme.breakpoints.down('md')]: {
      width: ' 75%',
    },
  },
  percentage: {
    fontSize: 20,
    color: theme.palette.text.black,
  },
  noDataFound: {
    margin: 20,
    fontSize: 20,
    textAlign: 'center',
  },
  textField: {
    position: 'absolute',
    top: '25%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    textAlign: 'center',
  },
}));

const WorkSchedule = () => {
  const dispatch = useDispatch();
  const { isMobile } = useIsMobileDevice();
  const {
    workScheduleImage,
    linearProgress,
    marginBottom,
    marginTop,
    cardContent,
    marginTop17,
    linerProgressTextField,
    percentage,
    marginCard,
    noDataFound,
    textField,
  } = useStyles();
  const [id, setId] = useState('');
  const [phaseListData, setPhaseListData] = useState([]);
  const [open, setOpen] = useState(false);
  const { projectId } = useSelector(({ project }) => project);
  const { progress, chartData, progressByPhase, phaseData } = useSelector(
    ({ workSchedule }) => workSchedule,
  );
  const [phaseName, setPhaseName] = useState('');
  const getChartData = (phaseId) => {
    dispatch(getWorkScheduleChartDataByPhase(phaseId));
    dispatch(getWorkScheduleProgressByPhase(phaseId));
    const getPhaseName = phaseListData.filter((rec) => rec._id === phaseId);
    setPhaseName(getPhaseName?.[0]?.name);
  };
  useEffect(() => {
    let isMounted = true;
    dispatch(getWorkScheduleTotalProgress(projectId));
    const getPhaseList = async () => {
      const response = await dispatch(getPhase(projectId));
      if (isMounted && response.status === 200) {
        const { phaseList } = response.data.data;
        getChartData(phaseList?.[0]?._id);
        setId(phaseList?.[0]?._id);
        setPhaseName(phaseList?.[0]?.name);
        setPhaseListData(phaseList);
      }
    };
    getPhaseList();
    return () => {
      isMounted = false;
    };
  }, []);
  return (
    <>
      {isMobile ? <PageHeader title={getTitle(ROUTES.WORKSCHEDULE)} /> : ''}
      <Div className={workScheduleImage}>
        <Typography
          variant={'h3'}
          color="common.white"
          className={marginBottom}
        >
          {`Total Progress (${moment(progress?.startDate).format(
            'DD/MM/YYYY',
          )} - ${moment(progress?.endDate).format('DD/MM/YYYY')})`}
        </Typography>
        <LinearProgress className={linearProgress} value={progress?.progress} />
      </Div>
      <Div className={marginTop}>
        <Card title="Phase Wise Progress" className={cardContent}>
          <Grid container spacing={2} className={marginCard}>
            <Grid item lg={6} xs={12}>
              <AppSelectBox
                required
                data={phaseListData}
                valueKey="_id"
                labelKey="name"
                label="Select Phase"
                value={id}
                variant="outlined"
                onChange={(event) => {
                  setId(event.target.value);
                  getChartData(event.target.value);
                }}
              />
            </Grid>
            <Grid item lg={6} xs={12} className={marginTop17}>
              <Box position="relative" flex={1}>
                <LinearProgressComponent
                  className={linerProgressTextField}
                  variant="determinate"
                  value={progressByPhase?.progress}
                  color="primary"
                />
                <JumboAppTextField
                  variant="outlined"
                  label="Showing Progress for Phase One"
                  value={` `}
                  disabled
                  className={textField}
                  size="normal"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        className={percentage}
                        position="end"
                      >{`${progressByPhase?.progress}%`}</InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Grid>
          </Grid>
          <br></br>
          <Typography variant={'h5'} mb={3} className="phase-main">
            <Grid container spacing={3} className="phase-inner">
              <Grid item xs={10} sm={11} className="text-white">
                {`Phase - ${phaseName} (${moment(phaseData?.startDate).format(
                  'DD/MM/YYYY',
                )} - ${moment(phaseData?.endDate).format('DD/MM/YYYY')})`}
              </Grid>
              <Grid item xs={2} sm={1}>
                <Tooltip title="View Chart" placement="top-start" arrow>
                  <Div
                    onClick={() => setOpen(true)}
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      marginLeft: 'auto',
                      backgroundColor: '#BDD52F',
                    }}
                  >
                    <RemoveRedEyeIcon
                      style={{ width: '24px', margin: '9px' }}
                    />
                  </Div>
                </Tooltip>
              </Grid>
            </Grid>
          </Typography>
          <Div className={marginTop}>
            {chartData.length ? (
              <Grantt chatData={chartDataCalculation(chartData)} />
            ) : (
              <Typography className={noDataFound} variant="body1">
                No task found
              </Typography>
            )}
          </Div>
        </Card>
        <Dialog
          className="custom-modal"
          fullWidth
          sx={{
            '& .MuiDialog-container': {
              '& .MuiPaper-root': {
                overflow: 'scroll',
                width: '100%',
                maxWidth: '900px',
              },
            },
          }}
          open={open}
          onClose={() => setOpen(false)}
        >
          <Card sx={{ display: 'flex' }}>
            <Div
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                margin: '0 10px',
              }}
            >
              <CardContent>
                {chartData.length ? (
                  <>
                    <Div
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBlock: '10px',
                      }}
                    >
                      <Tooltip title="Download PDF" placement="top-start" arrow>
                        <Div
                          onClick={(e) => downloadChart(e)}
                          style={{
                            width: '40px',
                            height: '40px',
                            borderRadius: '50%',
                            backgroundColor: '#BDD52F',
                          }}
                        >
                          <img
                            src={`${ASSET_IMAGES}/download.png`}
                            alt="Pidilite"
                            style={{
                              width: '22px',
                              margin: '12px 9px 9px 9px',
                            }}
                          />
                        </Div>
                      </Tooltip>
                      <Div
                        onClick={() => setOpen(false)}
                        style={{
                          width: '40px',
                          height: '40px',
                          borderRadius: '50%',
                          marginLeft: '10px',
                          backgroundColor: '#BDD52F',
                        }}
                      >
                        <CloseIcon style={{ width: '24px', margin: '9px' }} />
                      </Div>
                    </Div>
                    <div className="div2PDF">
                      <Div sx={{ margin: '20px' }}>
                        <Typography
                          variant={'h2'}
                          mb={3}
                          className="phase-main"
                        >
                          <Grid container spacing={3} className="phase-inner">
                            <Grid item xs={12} sm={12} className="text-white">
                              {`${phaseData?.name} Work Schedule for ${phaseData?.projectId?.name}`}
                            </Grid>
                          </Grid>
                        </Typography>
                        <Typography
                          variant={'h2'}
                          mb={5}
                          className="phase-main-2"
                        >
                          <Grid container spacing={3} className="phase-inner">
                            <Grid item xs={12} sm={12} className="text-black">
                              {`${moment(phaseData?.startDate).format(
                                'DD/MM/YYYY',
                              )} - ${moment(phaseData?.endDate).format(
                                'DD/MM/YYYY',
                              )} (${phaseData?.areaToBeCovered} Sqmt)`}
                            </Grid>
                          </Grid>
                        </Typography>
                        <Grantt chatData={chartDataCalculation(chartData)} />
                        <center>
                          <Typography variant={'h5'} mb={3} mt={3}>
                            {`Task Details as on ${moment(new Date()).format(
                              'MMM Do YYYY',
                            )}`}
                          </Typography>
                        </center>
                        <div className="chart-table-main">
                          <table className="chart-table">
                            <tr>
                              <th>Task name</th>
                              <th>Start Date</th>
                              <th>End Date</th>
                              <th>Progress</th>
                            </tr>
                            {chartData.map((val, key) => {
                              return (
                                <tr key={key}>
                                  <td>{val?.name}</td>
                                  <td>
                                    {moment(val?.startDate).format(
                                      'DD/MM/YYYY',
                                    )}
                                  </td>
                                  <td>
                                    {moment(val?.endDate).format('DD/MM/YYYY')}
                                  </td>
                                  <td>{`${val.progress}%`}</td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                        <br></br>
                        <Div
                          sx={{
                            py: 2,
                            px: { lg: 6, xs: 4 },
                            borderTop: 2,
                            borderColor: 'divider',
                            bgcolor: 'background.paper',
                          }}
                        >
                          <Div
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <Typography
                              variant={'body1'}
                              color={'text.primary'}
                            >
                              © Copyright Pidilite 2023. All Rights Reserved.
                            </Typography>
                          </Div>
                        </Div>
                      </Div>
                    </div>
                  </>
                ) : (
                  <NoDataPlaceholder />
                )}
              </CardContent>
            </Div>
          </Card>
        </Dialog>
      </Div>
    </>
  );
};
export default WorkSchedule;
