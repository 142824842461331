import {
  STORE_PROJECT_ID,
  REMOVE_PROJECT_LIST,
} from '../../utils/constants/constant';

const INIT_STATE = {
  projectId: null,
};

const reducerFunc = (state = INIT_STATE, action) => {
  switch (action.type) {
    case STORE_PROJECT_ID: {
      return {
        ...state,
        projectId: action.payload,
      };
    }
    case REMOVE_PROJECT_LIST: {
      return {
        ...INIT_STATE,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducerFunc;
