import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ROUTES } from 'app/utils/constants/routes';
import { getTitle } from 'app/utils/appHelpers';
import { useIsMobileDevice } from 'app/hooks/isMobile';
import Card from '@jumbo/components/common/Card';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import Div from '@jumbo/shared/Div/Div';
import Accordion from '@jumbo/components/common/Accordion';
import { getNotifications } from 'app/redux/actions/Notification';
import moment from 'moment';

const Project = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state?.notification);

  const { isMobile } = useIsMobileDevice();

  const columns = [
    {
      label: 'Title',
      key: 'title',
    },
    {
      label: 'Date',
      key: 'createdAt',
      render: (elm) => <>{moment(elm?.createdAt).format('DD-MM-YYYY')}</>,
    },
    {
      label: 'Description',
      key: 'body',
    },
  ];
  useEffect(() => {
    dispatch(getNotifications({ type: 'all' }));
  }, []);
  return (
    <>
      {isMobile ? <PageHeader title={getTitle(ROUTES.NOTIFICATION)} /> : ''}
      <Div>
        <Card title={'List of notification'}>
          <Accordion
            columns={columns}
            record={notifications?.list}
            mobileTitleKey={['title']}
          />
        </Card>
      </Div>
    </>
  );
};
export default Project;
