import React, { useState } from 'react';

import { makeStyles } from '@mui/styles';
import { useIsMobileDevice } from 'app/hooks/isMobile';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import Div from '@jumbo/shared/Div';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Avatar from '../Avatar';
import NoDataPlaceholder from '../NoDataFound';

const useStyles = makeStyles((theme) => ({
  mobileText: {
    paddingLeft: 4,
    display: 'block',
    width: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ellipsis: {
    display: 'block',
    width: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  marginTopSummary: {
    marginTop: 10,
  },
  root: {
    paddingLeft: 7,
    position: 'relative',
    background: 'transparent',
    boxShadow: 'none',
    '&::before': {
      background: 'transparent',
    },
    '& .MuiAccordionSummary-content': {
      minWidth: '100%',
    },
    '&.MuiPaper-root.MuiAccordion-root.Mui-expanded': {
      margin: 0,
    },
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  divRoot: {
    overflow: 'hidden',
    overflowX: 'auto',
    background: theme.palette.text.white,
  },
  cmCol: {
    '-ms-flex-preferred-size': '0',
    flexBasis: 0,
    '-webkit-box-flex': 1,
    '-ms-flex-positive': 1,
    flexGrow: 1,
    maxWidth: '100%',
    [theme.breakpoints.down('lg')]: {
      minWidth: 'max-content',
      padding: '0 0.5rem',
    },
  },
  icon: {
    maxWidth: '50px',
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: '25px',
    height: '25px',
  },
}));

const ControlledAccordions = ({
  record,
  columns,
  mobileTitleKey,
  onRowClick,
}) => {
  const classes = useStyles();
  const { isMobile } = useIsMobileDevice();
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel, record) => (_, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (!isMobile && onRowClick) {
      onRowClick(record);
    }
  };
  const handleChangeEow = (record) => {
    if (onRowClick) {
      onRowClick(record);
    }
  };
  return (
    <Div className={classes.divRoot}>
      {record?.length ? (
        record?.map((row, index) => (
          <Accordion
            className={classes.root}
            key={`row-${index}`}
            expanded={isMobile && expanded === index}
            onChange={handleChange(index, row)}
          >
            <AccordionSummary
              className={`${classes.content} ${
                onRowClick ? '' : 'cursor-normal'
              }`}
              expandIcon={isMobile && <ArrowDropDownIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              {isMobile
                ? mobileTitleKey?.map((singleKey, index) => {
                    const selectedColumn = columns?.find(
                      (rec) => rec?.key === singleKey,
                    );
                    return (
                      <Div className={classes.content} key={index}>
                        {row.icon ? (
                          <Avatar
                            icon={row.icon}
                            className={classes.avatar}
                            alt="profile"
                          />
                        ) : (
                          ''
                        )}
                        <Div onClick={() => handleChangeEow(row)}>
                          <Div className={classes.mobileText}>
                            {selectedColumn?.labelRender
                              ? selectedColumn?.labelRender(row, index)
                              : selectedColumn?.label}
                          </Div>
                          <Div className={classes.mobileText}>
                            {selectedColumn?.render
                              ? selectedColumn.render(row, index)
                              : row[singleKey]}
                          </Div>
                        </Div>
                      </Div>
                    );
                  })
                : columns.map((column, i) => (
                    <Div
                      className={`${classes.cmCol} ${
                        (column.key === 'icon' ||
                          column.key === 'actionIcon') &&
                        classes.icon
                      }`}
                      key={`all-col-${i}`}
                    >
                      <Div key={`col+${index}`}>
                        {column?.labelRender
                          ? column?.labelRender(row, index)
                          : column?.label}
                      </Div>
                      <Div
                        key={`row+${index}`}
                        className={column.ellipsis ? classes.ellipsis : ''}
                      >
                        {column.render ? (
                          column.render(row, index)
                        ) : column.key === 'icon' ? (
                          <Avatar
                            className={classes.avatar}
                            icon={row.icon}
                            alt="profile"
                          />
                        ) : (
                          row[column.key]
                        )}
                      </Div>
                    </Div>
                  ))}
            </AccordionSummary>
            <AccordionDetails
              className={classes.summaryContent}
              key={`details-${index}`}
            >
              {columns.map((column, i) =>
                !mobileTitleKey.includes(column?.key) &&
                column?.key !== 'icon' ? (
                  <div key={`column-${i}`} className={classes.marginTopSummary}>
                    <Typography>
                      {' '}
                      {column?.labelRender
                        ? column?.labelRender(row, index)
                        : column?.label}
                    </Typography>
                    <Typography
                      className={column.ellipsis ? classes.ellipsis : ''}
                    >
                      {column.render
                        ? column.render(row, index)
                        : row[column.key]}
                    </Typography>
                  </div>
                ) : null,
              )}
            </AccordionDetails>
          </Accordion>
        ))
      ) : (
        <NoDataPlaceholder />
      )}
    </Div>
  );
};

export default ControlledAccordions;
