import React from 'react';
import Chart from 'react-google-charts';

const Grantt = ({ chatData }) => {
  const options = {
    gantt: {
      criticalPathEnabled: false,
    },
  };
  return (
    <Chart
      chartType="Gantt"
      width="100%"
      height={chatData.length * 50}
      data={chatData}
      rootProps={{ 'data-testid': '1' }}
      options={options}
    />
  );
};
export default Grantt;
