import React from 'react';
import Div from '@jumbo/shared/Div';
import { makeStyles } from '@mui/styles';
import { ASSET_IMAGES } from 'app/utils/constants/paths';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: '100%',
    },
  };
});

const Logo = ({ src, alt, className }) => {
  const classes = useStyles();

  return (
    <Div className={classes.root}>
      <img className={className} src={`${ASSET_IMAGES}/${src}`} alt={alt} />
    </Div>
  );
};

Logo.defaultProps = {
  mode: 'light',
};

export default Logo;
