import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { ROUTES } from 'app/utils/constants/routes';
import { getTitle } from 'app/utils/appHelpers';
import { useIsMobileDevice } from 'app/hooks/isMobile';
import { getProjectTeam } from 'app/redux/actions/project';
import Card from '@jumbo/components/common/Card';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import Div from '@jumbo/shared/Div/Div';
import Accordion from '@jumbo/components/common/Accordion';
import { CardContent, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { ASSET_IMAGES } from 'app/utils/constants/paths';

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: 30,
  },
}));
const Project = () => {
  const dispatch = useDispatch();
  const { projectId } = useSelector(({ project }) => project);
  const { marginTop } = useStyles();
  const { isMobile } = useIsMobileDevice();
  const [project, setProject] = useState({
    aseId: [],
    supervisorId: [],
    clientId: [],
  });
  const columns = [
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Designation',
      key: 'designation',
      ellipsis: true,
    },
    {
      label: 'Mobile',
      key: 'mobileNumber',
    },
    {
      label: 'Email',
      key: 'email',
    },
  ];
  useEffect(() => {
    const projectListing = async () => {
      const response = await dispatch(getProjectTeam(projectId));
      if (response?.status === 200) {
        setProject(response?.data?.data?.projectDetail);
      }
    };
    projectListing();
  }, []);
  return (
    <>
      {isMobile ? <PageHeader title={getTitle(ROUTES.PROJECT)} /> : ''}
      <Div>
        <Card title={'Info'}>
          <Div sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
            <CardContent className="p-15">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Div
                        sx={{
                          width: { xs: 'auto' },
                          flexShrink: 0,
                          px: 1,
                          flex: { xs: '1', lg: '0 1 auto' },
                        }}
                      >
                        <Typography
                          fontSize={'14px'}
                          mb={0.5}
                          variant={'body1'}
                        >
                          Project Name
                        </Typography>
                        <Typography variant={'h3'} fontSize={18}>
                          {project?.name}
                        </Typography>
                      </Div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Div
                        sx={{
                          width: { xs: 'auto' },
                          flexShrink: 0,
                          px: 1,
                          flex: { xs: '1', lg: '0 1 auto' },
                        }}
                      >
                        <Typography
                          fontSize={'14px'}
                          mb={0.5}
                          variant={'body1'}
                        >
                          ID
                        </Typography>
                        <Typography variant={'h3'} fontSize={18}>
                          {project?.uniqId}
                        </Typography>
                      </Div>
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Div
                        sx={{
                          width: { xs: 'auto' },
                          flexShrink: 0,
                          px: 1,
                          flex: { xs: '1', lg: '0 1 auto' },
                        }}
                      >
                        <Typography
                          fontSize={'14px'}
                          mb={0.5}
                          variant={'body1'}
                        >
                          Location
                        </Typography>
                        <Typography variant={'h3'} fontSize={18}>
                          {project?.city}
                        </Typography>
                      </Div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Div
                        sx={{
                          width: { xs: 'auto' },
                          flexShrink: 0,
                          px: 1,
                          flex: { xs: '1', lg: '0 1 auto' },
                        }}
                      >
                        <Typography
                          fontSize={'14px'}
                          mb={0.5}
                          variant={'body1'}
                        >
                          Total Area
                        </Typography>
                        <Typography variant={'h3'} fontSize={18}>
                          {`${project?.totalArea} sqmt`}
                        </Typography>
                      </Div>
                    </Grid>
                  </Grid>
                  <br></br>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Div
                        sx={{
                          width: { xs: 'auto' },
                          flexShrink: 0,
                          px: 1,
                          flex: { xs: '1', lg: '0 1 auto' },
                        }}
                      >
                        <Typography
                          fontSize={'14px'}
                          mb={0.5}
                          variant={'body1'}
                        >
                          Project Description
                        </Typography>
                        <Typography variant={'h3'} fontSize={18}>
                          {project?.description}
                        </Typography>
                      </Div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  style={{ minHeight: 'auto' }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <Div sx={{ mt: 'auto' }}>
                        <img
                          src={`${ASSET_IMAGES}/dashboradImg.png`}
                          style={{ width: '190px' }}
                          alt="Pidilite"
                        />
                      </Div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Div
                        sx={{
                          width: { xs: 'auto' },
                          flexShrink: 0,
                          px: 1,
                          flex: { xs: '1', lg: '0 1 auto' },
                        }}
                      >
                        <Typography variant={'h3'} mb={0.5} fontSize={18}>
                          Project Completion Date
                        </Typography>
                        <Typography fontSize={'20px'} variant={'body1'}>
                          {project.endDate
                            ? moment(project.endDate).format('DD/MM/YYYY')
                            : ''}
                        </Typography>
                      </Div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Div>
        </Card>
      </Div>
      <br />
      <br />
      <Div>
        <Card title={'Pidilite Team'}>
          <Accordion
            columns={columns}
            record={project?.aseId}
            mobileTitleKey={['name']}
          />
        </Card>
      </Div>
      <Div className={marginTop}>
        <Card title="Application Team">
          <Accordion
            columns={columns}
            record={project?.supervisorId}
            mobileTitleKey={['name']}
          />
        </Card>
      </Div>
      <Div className={marginTop}>
        <Card title="Customer Team">
          <Accordion
            columns={columns}
            record={project?.clientId}
            mobileTitleKey={['name']}
          />
        </Card>
      </Div>
    </>
  );
};
export default Project;
