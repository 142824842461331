import {
  GET_REPORT_TYPE_LIST,
  GET_REPORTS_LIST,
  GET_OLD_REPORTS_LIST,
  UPDATE_REPORTS_LIST,
  UPDATE_OLD_REPORTS_LIST,
  REMOVE_REPORTS_LIST,
} from '../../utils/constants/constant';

const INIT_STATE = {
  reportType: [],
  reports: [],
  oldReports: [],
};

const reducerFunc = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_REPORT_TYPE_LIST: {
      return {
        ...state,
        reportType: action.payload,
      };
    }
    case GET_REPORTS_LIST: {
      return {
        ...state,
        reports: action.payload,
      };
    }
    case UPDATE_REPORTS_LIST: {
      return {
        ...state,
        reports: action.payload,
      };
    }
    case GET_OLD_REPORTS_LIST: {
      return {
        ...state,
        oldReports: action.payload,
      };
    }
    case UPDATE_OLD_REPORTS_LIST: {
      return {
        ...state,
        oldReports: action.payload,
      };
    }
    case REMOVE_REPORTS_LIST: {
      return {
        ...INIT_STATE,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducerFunc;
