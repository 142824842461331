import axios from 'axios';

import {
  GET_WORK_SCHEDULE_TOTAL_PROGRESS,
  GET_WORK_SCHEDULE_CHART_DATA,
  GET_WORK_SCHEDULE_PROGRESS_BY_PHASE,
  REMOVE_WORK_SCHEDULE_DATA,
} from '../../utils/constants/constant';

export const getWorkScheduleTotalProgress = (projectId) => {
  return (dispatch, getState) => {
    return axios
      .get(`work-schedule/${projectId}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_WORK_SCHEDULE_TOTAL_PROGRESS,
            payload: response?.data?.data?.projectDetail,
          });
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const getWorkScheduleChartDataByPhase = (phaseId) => {
  return (dispatch, getState) => {
    return axios
      .get(`work-schedule/chart/${phaseId}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_WORK_SCHEDULE_CHART_DATA,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const getWorkScheduleProgressByPhase = (phaseId) => {
  return (dispatch, getState) => {
    return axios
      .get(`work-schedule/phase/${phaseId}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_WORK_SCHEDULE_PROGRESS_BY_PHASE,
            payload: response?.data?.data?.phaseDetail,
          });
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const removeWorkScheduleData = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_WORK_SCHEDULE_DATA,
    });
  };
};
