export const viewReportRoute = (reportId, phaseId, categoryId) =>
  `/report-view/${reportId}/${phaseId}/${categoryId}`;
export const viewDiscussionRoute = (id) => `/discussion-view/${id}`;
const TITLES_BY_ROUTE = {
  DASHBOARD: 'Dashboard',
  WORKSCHEDULE: 'Work Schedule',
  REPORTS: 'Reports',
  REPORTSVIEW: 'Report View',
  FEEDBACKMENU: 'Feedback',
  FEEDBACK: 'Feedback Form',
  PROJECT: 'Project Details',
  DISCUSSION: 'Discussion Forum',
  LETSDISCUSSION: 'Lets Discuss',
  DISCUSSIONLIST: 'Discussion Threads',
  VIEWDISCUSSION: 'View Discussion',
  PROFILE: 'Update Profile',
  FORGETPASSWORD: 'Forget Password',
  RESETPASSWORD: 'Reset Password',
  NOTIFICATION: 'Notification',
};
const ROUTES = {
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  FORGETPASSWORD: '/forget-password',
  RESETPASSWORD: '/reset-password',
  SETPASSWORD: '/set-password',
  WORKSCHEDULE: '/work-schedule',
  PROJECT: '/project',
  REPORTS: '/report',
  REPORTSVIEW: '/report-view/:reportId/:categoryId/:phaseId',
  FEEDBACK: '/feedback',
  DISCUSSION: '/discussion',
  VIEWDISCUSSION: '/discussion-view/:id',
  PROFILE: '/profile',
  ERROR_404: '/404',
  NOTIFICATION: '/notification',
};

export { ROUTES, TITLES_BY_ROUTE };
