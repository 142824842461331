import React from 'react';

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';
import Logo from 'app/shared/Logo/Logo';
import { useSelector } from 'react-redux';
import { useIsMobileDevice } from 'app/hooks/isMobile';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '30px',
  },
  footerLogo: {
    width: '75px',
  },
}));
const Footer = () => {
  const classes = useStyles();
  const { authUserInformation } = useSelector(
    ({ userInformation }) => userInformation,
  );
  const { isMobile } = useIsMobileDevice();
  return (
    <Div className='px-sm-8'
      sx={{
        py: 2,
        px: { lg: 6, xs: 4 },
        borderTop: 2,
        borderColor: 'divider',
        bgcolor: 'background.paper',
        display: isMobile ? 'block' : 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {!authUserInformation?._id && (
        <Div sx={{ textAlign: 'center' }} justifyContent="start">
          <Typography variant={'h5'} mb={1}>
            An initiative by
          </Typography>
          <Div sx={{ mt: 'auto' }}>
            <Logo src={`pidilite_footer.png`} className={classes.footerLogo} />
          </Div>
        </Div>
      )}
      {isMobile ? <><Div
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: !authUserInformation?._id ? 'center' : 'space-between',
          width: authUserInformation?._id ? '100%' : 'auto',
          marginTop: { xs: 2 },
        }}
      >
        <Typography variant={'body1'} color={'text.primary'}>
          © Copyright Pidilite 2024. All Rights Reserved.
        </Typography>
      </Div>
        <Div sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography
            variant={'body1'}
            sx={{
              display: { sm: 'block' },
              mb: 0,
              mr: 2,
              color: 'text.primary',
            }}
          >
            Designed & Developed by
          </Typography>
          <Logo src={`v4web.png`} alt="v4web" className={classes.root} />
        </Div></> : <Div
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: !authUserInformation?._id ? 'end' : 'space-between',
            width: authUserInformation?._id ? '100%' : 'auto',
            marginTop: { xs: 2 },
          }}
        >
        <Typography variant={'body1'} color={'text.primary'}>
          © Copyright Pidilite 2024. All Rights Reserved.
        </Typography>
        <Div sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant={'body1'}
            sx={{
              display: { xs: 'none', sm: 'block' },
              mb: 0,
              mr: 2,
              color: 'text.primary',
            }}
          >
            Designed & Developed by
          </Typography>
          <Logo src={`v4web.png`} alt="v4web" className={classes.root} />
        </Div>
      </Div>}

    </Div>
  );
};

export default Footer;
