import React from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

const JumboButton = ({ variant, shape, size, sx, ...restProps }) => {
  return <Button variant={variant} size={size} {...restProps} />;
};
/* Todo define */
JumboButton.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  shape: PropTypes.string,
  sx: PropTypes.object,
  size: PropTypes.string,
};
export default JumboButton;
