import { useSelector } from 'react-redux';

const useAuth = () => {
  const { authUserInformation } = useSelector(
    ({ userInformation }) => userInformation,
  );
  return authUserInformation?._id ? true : false;
};

export default useAuth;
