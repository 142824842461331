import {
  GET_WORK_SCHEDULE_TOTAL_PROGRESS,
  GET_WORK_SCHEDULE_CHART_DATA,
  GET_WORK_SCHEDULE_PROGRESS_BY_PHASE,
  REMOVE_WORK_SCHEDULE_DATA,
} from '../../utils/constants/constant';

const INIT_STATE = {
  progress: {},
  chartData: [],
  progressByPhase: {},
  phaseData:{}
};

const reducerFunc = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WORK_SCHEDULE_TOTAL_PROGRESS: {
      return {
        ...state,
        progress: action.payload,
      };
    }
    case GET_WORK_SCHEDULE_CHART_DATA: {
      return {
        ...state,
        chartData: action.payload?.taskChartData,
        phaseData: action.payload?.phaseData
      };
    }
    case GET_WORK_SCHEDULE_PROGRESS_BY_PHASE: {
      return {
        ...state,
        progressByPhase: action.payload,
      };
    }
    case REMOVE_WORK_SCHEDULE_DATA: {
      return {
        ...INIT_STATE,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducerFunc;
