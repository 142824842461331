import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PersistGate } from 'redux-persist/integration/react';

import JumboApp from '@jumbo/components/JumboApp';
import AppLayout from './AppLayout';
import JumboTheme from '@jumbo/components/JumboTheme';
import AppRoutes from './AppRoutes';
import configureStore, { history } from './redux/store';
import { SnackbarProvider, useSnackbar } from 'notistack';
import AppProvider from './AppProvider';
import { config } from './config/main';
import JumboRTL from '@jumbo/JumboRTL/JumboRTL';
import Div from '@jumbo/shared/Div';
import { CircularProgress, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import axiosUtil from 'app/services/axios';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)}>
      <CancelIcon sx={{ color: '#ffffff' }} />
    </IconButton>
  );
}
const { store, persistor } = configureStore();
axiosUtil.initalise(store, history);
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={<CircularProgress />} persistor={persistor}>
          <BrowserRouter history={history}>
            <AppProvider>
              <JumboApp activeLayout={config.defaultLayout}>
                <JumboTheme init={config.theme}>
                  <JumboRTL>
                    <SnackbarProvider
                      hideIconVariant
                      action={(snackbarKey) => (
                        <SnackbarCloseButton snackbarKey={snackbarKey} />
                      )}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      maxSnack={3}
                      autoHideDuration={20000}
                    >
                      <AppLayout>
                        <Suspense
                          fallback={
                            <Div
                              sx={{
                                display: 'flex',
                                minWidth: 0,
                                alignItems: 'center',
                                alignContent: 'center',
                                height: '100%',
                              }}
                            >
                              <CircularProgress sx={{ m: '-40px auto 0' }} />
                            </Div>
                          }
                        >
                          <AppRoutes />
                        </Suspense>
                      </AppLayout>
                    </SnackbarProvider>
                  </JumboRTL>
                </JumboTheme>
              </JumboApp>
            </AppProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
