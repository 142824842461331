import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { useIsMobileDevice } from 'app/hooks/isMobile';
import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import { getTitle } from 'app/utils/appHelpers';
import { ROUTES } from 'app/utils/constants/routes';
import { getById, updateInformation } from 'app/redux/actions/auth';

import JumboButton from '@jumbo/components/JumboButton/JumboButton';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import Card from '@jumbo/components/common/Card';
import JumboAppTextField from '@jumbo/components/JumboAppTextField/JumboAppTextField';
import Div from '@jumbo/shared/Div/Div';
import JumboAppPasswordTextField from '@jumbo/components/JumboAppTextField/JumboAppPasswordTextField';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: 24,
    '&:last-child': {
      paddingBottom: '18px',
    },
  },
  cancelBtn: {
    marginLeft: 5,
  },
  buttonAction: {
    display: 'flex',
    marginTop: '24px',
  },
}));

const Profile = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { authUserInformation } = useSelector(
    ({ userInformation }) => userInformation,
  );
  const { isMobile } = useIsMobileDevice();
  const [name, setName] = useState('');
  const [designation, setDesignation] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');

  const id = authUserInformation?._id;

  useEffect(() => {
    const getProfileInformation = async () => {
      const userInformation = await dispatch(getById(authUserInformation?._id));
      if (userInformation?.status === 200) {
        const { name, email, mobileNumber, designation } =
          userInformation.data.data.userDetail;
        setName(name);
        setEmail(email);
        setMobileNumber(mobileNumber);
        setDesignation(designation);
      }
    };
    getProfileInformation();
  }, [authUserInformation, dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const userInformation = {
      _id: id,
      name,
      mobileNumber,
      designation,
    };
    if (password) {
      userInformation.password = password;
    }
    const response = await dispatch(updateInformation(userInformation));
    if (response.status === 200) {
      enqueueSnackbar(response.data.responseMessage, {
        variant: 'success',
      });
      navigate('/dashboard');
    } else {
      enqueueSnackbar(response.error, {
        variant: 'error',
      });
    }
  };
  const handleClosed = () => {
    navigate('/dashboard');
  };
  return (
    <>
      {isMobile && <PageHeader title={getTitle(ROUTES.PROFILE)} />}
      <Card className={classes.cardContent}>
        <form onSubmit={handleSubmit}>
          <Grid container rowSpacing={3}>
            <Grid item xs={12}>
              <JumboAppTextField
                type="text"
                label="Name*"
                name="name"
                size="normal"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <JumboAppTextField
                type="text"
                label="Email*"
                name="email"
                size="normal"
                disabled
                value={email}
              />
            </Grid>
            <Grid item xs={12}>
              <JumboAppTextField
                type="text"
                label="Designation*"
                name="designation"
                size="normal"
                value={designation}
                onChange={(event) => setDesignation(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <JumboAppPasswordTextField
                type="password"
                label="Password"
                name="password"
                size="normal"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <JumboAppTextField
                type="text"
                label="Mobile Number*"
                name="mobileNumber"
                size="normal"
                value={mobileNumber}
                onChange={(event) => setMobileNumber(event.target.value)}
              />
            </Grid>
          </Grid>
          <Div className={classes.buttonAction}>
            <JumboButton
              type="submit"
              variant="contained"
              size="medium"
              color="primary"
            >
              Submit
            </JumboButton>
            <JumboButton
              type="button"
              className={classes.cancelBtn}
              variant="contained"
              size="medium"
              color="error"
              onClick={handleClosed}
            >
              Cancel
            </JumboButton>
          </Div>
        </form>
      </Card>
    </>
  );
};

export default Profile;
