import React from 'react';

import { makeStyles } from '@mui/styles';
import { LinearProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 20,
  },
  bar: {
    borderRadius: 5,
  },
  text: {
    marginLeft: theme.spacing(1),
  },
}));

const LinearProgressComponent = ({ value, className }) => {
  const { text, root, bar } = useStyles();
  return (
    <Box className={className}>
      <LinearProgress
        color="search"
        classes={{
          root,
          bar,
        }}
        variant="determinate"
        value={value}
      />
      <Typography variant="body2" color="common.white" className={text}>
        {value}%
      </Typography>
    </Box>
  );
};
export default LinearProgressComponent;
