import axios from 'axios';

import {
  GET_DASHBOARD_LAST_REPORT_LIST,
  GET_DASHBOARD_ON_GOING_DISCUSSION_LIST,
  GET_DASHBOARD_DETAIL,
  REMOVE_ALL_DASHBOARD_LIST,
} from '../../utils/constants/constant';

export const getDashboardGraph = (projectId) => {
  return (dispatch, getState) => {
    return axios
      .get(`dashboard/project-completion-graph/${projectId}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const getLastUpdateReport = (projectId) => {
  return (dispatch, getState) => {
    return axios
      .get(`dashboard/report/list/${projectId}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_DASHBOARD_LAST_REPORT_LIST,
            payload: response?.data?.data?.lastUpdatedReportsList,
          });
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const getOngoingDiscussion = (projectId) => {
  return (dispatch, getState) => {
    return axios
      .get(`dashboard/ongoing-discussion/${projectId}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_DASHBOARD_ON_GOING_DISCUSSION_LIST,
            payload: response?.data?.data?.ongoingDiscussionList,
          });
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const getDashboardDetail = (projectId) => {
  return (dispatch, getState) => {
    return axios
      .get(`dashboard/detail/${projectId}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_DASHBOARD_DETAIL,
            payload: response?.data?.data?.projectDetail,
          });
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const removeDashboardData = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ALL_DASHBOARD_LIST,
    });
  };
};
