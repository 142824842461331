import {
  GET_DISCUSSION_LIST,
  VIEW_DISCUSSION_LIST,
  REMOVE_DISCUSSION_LIST,
} from '../../utils/constants/constant';

const INIT_STATE = {
  discussionList: [],
  viewDiscussion: [],
};

const reducerFunc = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DISCUSSION_LIST: {
      return {
        ...state,
        discussionList: action.payload,
      };
    }
    case VIEW_DISCUSSION_LIST: {
      return {
        ...state,
        viewDiscussion: action.payload,
      };
    }
    case REMOVE_DISCUSSION_LIST: {
      return {
        ...INIT_STATE,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducerFunc;
