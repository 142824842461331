import React from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from 'app/hooks/isAuth';
import { ROUTES } from 'app/utils/constants/routes';
import Dashboard from '../pages/dashboard';
import WorkSchedule from 'app/pages/workschedule';
import Project from 'app/pages/project';
import Page from '@jumbo/shared/Page';
import Login from 'app/pages/login';
import Reports from 'app/pages/report';
import ReportsView from 'app/pages/report/oldReport';
import Feedback from 'app/pages/feedback';
import Discussion from 'app/pages/discussion';
import ViewDiscussion from 'app/pages/discussion/viewDiscussion';
import Profile from 'app/pages/profile';
import Notification from 'app/pages/notification';
import Error404 from 'app/pages/pagenotfound';
import ForgetPassword from 'app/pages/forgetpassword';
import ResetPassword from 'app/pages/resetpassword';

const PrivateRoute = ({ children }) => {
  const authUserInformation = useAuth();
  if (authUserInformation) {
    return children;
  }
  return <Navigate to="/login" />;
};
const routesForPublic = [
  {
    path: '*',
    element: (
      <Page component={Error404} layout={'solo-page'} disableSmLogin={true} />
    ),
  },
];
const routesForAuthenticatedOnly = [
  {
    path: '/',
    element: (
      <PrivateRoute>
        <Navigate to={'/dashboard'} />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.DASHBOARD,
    element: (
      <PrivateRoute>
        <Page component={Dashboard} />
      </PrivateRoute>
    ),
  },

  {
    path: ROUTES.WORKSCHEDULE,
    element: (
      <PrivateRoute>
        <Page component={WorkSchedule} />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.PROJECT,
    element: (
      <PrivateRoute>
        <Page component={Project} />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.REPORTS,
    element: (
      <PrivateRoute>
        <Page component={Reports} />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.REPORTSVIEW,
    element: (
      <PrivateRoute>
        <Page component={ReportsView} />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.FEEDBACK,
    element: (
      <PrivateRoute>
        <Page component={Feedback} />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.DISCUSSION,
    element: (
      <PrivateRoute>
        <Page component={Discussion} />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.VIEWDISCUSSION,
    element: (
      <PrivateRoute>
        <Page component={ViewDiscussion} />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.PROFILE,
    element: (
      <PrivateRoute>
        <Page component={Profile} />
      </PrivateRoute>
    ),
  },
  {
    path: ROUTES.NOTIFICATION,
    element: (
      <PrivateRoute>
        <Page component={Notification} />
      </PrivateRoute>
    ),
  },
];
const routesForNotAuthenticatedOnly = [
  {
    path: ROUTES.LOGIN,
    element: (
      <Page component={Login} layout={'solo-page'} disableSmLogin={true} />
    ),
  },
  {
    path: ROUTES.FORGETPASSWORD,
    element: (
      <Page
        component={ForgetPassword}
        layout={'solo-page'}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: ROUTES.RESETPASSWORD,
    element: (
      <Page
        component={ResetPassword}
        layout={'solo-page'}
        disableSmLogin={true}
      />
    ),
  },
  {
    path: ROUTES.SETPASSWORD,
    element: (
      <Page
        component={ResetPassword}
        layout={'solo-page'}
        disableSmLogin={true}
      />
    ),
  },
];

const routes = [
  ...routesForPublic,
  ...routesForAuthenticatedOnly,
  ...routesForNotAuthenticatedOnly,
];

export {
  routes as default,
  routesForPublic,
  routesForNotAuthenticatedOnly,
  routesForAuthenticatedOnly,
};
