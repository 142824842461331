import React, { useEffect, useState } from 'react';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { useIsMobileDevice } from 'app/hooks/isMobile';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { Avatar } from '@mui/material';
import { getAssetPath, getTitle } from '../../../app/utils/appHelpers';
import { ROUTES } from 'app/utils/constants/routes';
import {
  getDashboardGraph,
  getLastUpdateReport,
  getOngoingDiscussion,
  getDashboardDetail,
} from 'app/redux/actions/dashboard';
import Div from '@jumbo/shared/Div/Div';
import Card from '@jumbo/components/common/Card';
import Grid from '@mui/material/Grid';
import Accordion from '@jumbo/components/common/Accordion';
import WelcomeClient from '@jumbo/components/WelcomeCard';
import OverallProjectStatus from '@jumbo/components/OverAllProjectCard';
import ProjectCompletionDate from '@jumbo/components/ProjectCompletionDateCard';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import Bar from '@jumbo/components/Charts/Bar';
import Stack from '@mui/material/Stack';

Chart.register(CategoryScale);

const useStyles = makeStyles((theme) => ({
  marginTp: {
    marginTop: 40,
  },
  welcomeImage: {
    position: 'relative',
    background: `url(${getAssetPath(
      `${ASSET_IMAGES}/welcome_client.png`,
      '640x428',
    )}) no-repeat center`,
    backgroundSize: 'cover',
  },
  cardContent: {
    padding: 24,
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  cardContentChart: {
    padding: 24,
    '&:last-child': {
      paddingBottom: '18px',
    },
  },
  avatarIcon: {
    width: 40,
    height: 40,
  },
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobile } = useIsMobileDevice();
  const { projectId } = useSelector(({ project }) => project);
  const { lastUploadReport, ongoingDiscussionList, detail } = useSelector(
    ({ dashboard }) => dashboard,
  );
  const columns = [
    {
      label: ' ',
      key: 'icon',
      render: (elm) => (
        <>
          <Avatar
            className={classes.avatarIcon}
            icon={elm?.attachmentExt === '.pdf' ? 'pdfImg.png' : ''}
          />
        </>
      ),
    },
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Category',
      key: 'category',
      ellipsis: true,
      render: (elm) => <>{elm?.category?.name}</>,
    },
    {
      label: 'Phase',
      key: 'phase',
      render: (elm) => <>{elm?.phase?.name}</>,
    },
    {
      label: 'Comment',
      key: 'description',
      ellipsis: true,
    },
    {
      label: 'Uploaded On',
      key: 'updatedAt',
      render: (elm) => <>{moment(elm?.updatedAt).format('DD-MM-YYYY')}</>,
    },
  ];
  const columnOngoing = [
    {
      label: 'Discussion No.',
      key: 'ticketNumber',
    },
    {
      labelRender: (elm) => <>{elm?.topicId?.name}</>,
      key: 'ticketTitle',
      ellipsis: true,
    },
    {
      label: 'Date',
      key: 'updatedAt',
      render: (elm) => <>{moment(elm?.updatedAt).format('DD-MM-YYYY')}</>,
    },
  ];
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';

            if (label) {
              label += ' : ';
            }
            if (context.parsed.y !== null) {
              label += `${context.parsed.y}%`;
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: 'Progress of Phase (%)',
        },
      },
    },
  };
  useEffect(() => {
    const getGraphData = async () => {
      const response = await dispatch(getDashboardGraph(projectId));
      if (response.status === 200) {
        const { projectCompletionGraphData } = response.data.data;
        let label = [];
        let dataset = [];
        if (projectCompletionGraphData?.length) {
          projectCompletionGraphData.forEach((element) => {
            label = [...label, element.name];
            dataset = [...dataset, element.progress];
          });
          setData({
            labels: [...label],
            datasets: [
              {
                label: 'phase',
                backgroundColor: '#0493D1',
                borderColor: '#0493D1',
                data: dataset,
                barThickness: 40,
              },
            ],
          });
        }
      }
    };
    getGraphData();
    dispatch(getLastUpdateReport(projectId));
    dispatch(getOngoingDiscussion(projectId));
    dispatch(getDashboardDetail(projectId));
  }, []);
  const onRowClick = (record) => {
    navigate('/report?reportId=' + record?._id);
  };
  const onRowClickOngoing = (record) => {
    navigate(`/discussion-view/${record?._id}`);
  };
  return (
    <>
      {isMobile ? <PageHeader title={getTitle(ROUTES.DASHBOARD)} /> : ''}
      <Grid container>
        <Grid item xs={12} lg={12}>
          <WelcomeClient detail={detail} />
        </Grid>
        {isMobile && (
          <>
            <Grid item md={4} xs={12}>
              <Div className={classes.marginTp}>
                <Card
                  title="Overall Projects Status"
                  className={classes.cardContent}
                >
                  <OverallProjectStatus progress={detail?.overallProgress} />
                </Card>
              </Div>
            </Grid>
            <Grid item md={4} xs={12}>
              <Div className={classes.marginTp}>
                <Card
                  title="Project Completion Date"
                  className={classes.cardContent}
                >
                  <Div color="common.white" className={classes.welcomeImage}>
                    <ProjectCompletionDate
                      date={detail?.projectCompletionDate}
                      days={detail?.remainingDays}
                      isExceed={detail?.isExceed}
                    />
                  </Div>
                </Card>
              </Div>
            </Grid>
          </>
        )}
      </Grid>
      <Div className={classes.marginTp}>
        <Card title="Phase Wise Status" className={classes.cardContentChart}>
          <Stack spacing={3}>
            <Bar chartData={data} options={options} />
          </Stack>
        </Card>
      </Div>
      <Div className={classes.marginTp}>
        <Card title="Recently Updated Reports">
          <Accordion
            columns={columns}
            record={lastUploadReport}
            mobileTitleKey={['name']}
            onRowClick={onRowClick}
          />
        </Card>
      </Div>
      <Div className={classes.marginTp}>
        <Card title="Ongoing Discussion">
          <Accordion
            columns={columnOngoing}
            record={ongoingDiscussionList}
            mobileTitleKey={['ticketNumber']}
            onRowClick={onRowClickOngoing}
          />
        </Card>
      </Div>
    </>
  );
};

export default Dashboard;
