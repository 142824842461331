import { useMediaQuery } from '@mui/material';

export const useIsMobileDevice = () => {
  const isMobile = useMediaQuery('(max-width:575px)');
  return {
    isMobile,
  };
};
export const useIsTabDevice = () => {
  const isTabDevice = useMediaQuery('(max-width:991.98px)');
  return {
    isTabDevice,
  };
};

export const useIsLargeDevice = () => {
  const isLargeDevice = useMediaQuery('(min-width:1441px)');
  return {
    isLargeDevice,
  };
};
