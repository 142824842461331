import React from 'react';

import { makeStyles } from '@mui/styles';
import Div from '@jumbo/shared/Div';
import { Typography } from '@mui/material';
import Logo from 'app/shared/Logo/Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 250,
    [theme.breakpoints.down('md')]: {
      width: 200,
    },
  },
}));

const NoDataPlaceholder = ({ children }) => {
  const classes = useStyles();
  if (children) return children;
  return (
    <Div sx={{ textAlign: 'center', p: 3, m: 'auto' }}>
      <Logo
        className={classes.root}
        alt={'Not Found'}
        src={`no-data-found.png`}
        style={{ verticalAlign: 'middle' }}
      />
      <Typography variant={'h3'} color={'text.secondary'} mt={2}>
        No data available
      </Typography>
    </Div>
  );
};

export default NoDataPlaceholder;
