import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { Card, CardHeader, CardContent, Typography } from '@mui/material';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { useIsMobileDevice } from 'app/hooks/isMobile';
import { getAssetPath } from '../../../app/utils/appHelpers';
import Span from '@jumbo/shared/Span/Span';
import Div from '@jumbo/shared/Div/Div';
import Grid from '@mui/material/Grid';
import Logo from 'app/shared/Logo/Logo';
import OverallProjectStatus from '@jumbo/components/OverAllProjectCard';
import ProjectCompletionDate from '@jumbo/components/ProjectCompletionDateCard';
import { getStep } from '@jumbo/utils/stage';

const useStyles = makeStyles((theme) => ({
  welcomeImage: {
    position: 'relative',
    background: `url(${getAssetPath(
      `${ASSET_IMAGES}/welcome_client.png`,
      '640x428',
    )}) no-repeat center`,
    backgroundSize: 'cover',
    color: theme.palette.text.white,
  },
  cardHeader: {
    '& .MuiCardHeader-title': {
      color: theme.palette.text.white,
    },
  },
  cardContent: {
    padding: '24px',
    [theme.breakpoints.down('sm')]: {
      padding: ' 0 24px 24px 24px',
    },
  },
  cardTitle: {
    marginBottom: 10,
  },
  cardContentText: {
    marginBottom: 5,
    display: 'flex',
  },
  text: {
    marginLeft: 5,
  },
  root: {
    width: 12,
  },
}));

const WelcomeClient = ({ detail }) => {
  const { authUserInformation } = useSelector(
    ({ userInformation }) => userInformation,
  );
  const { isMobile } = useIsMobileDevice();
  const navigate = useNavigate();
  const classes = useStyles();
  const [step] = useState(getStep(authUserInformation?.projectProgress));
  return (
    <Card className={classes.welcomeImage}>
      <CardHeader
        className={classes.cardHeader}
        title={`Welcome ${authUserInformation?.name}`}
      />
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item xs={12} md={4} lg={4}>
            <Typography variant={'body1'} className={classes.cardTitle}>
              You Have
            </Typography>
            <Div
              variant={'body1'}
              className={classes.cardContentText}
              onClick={() => navigate('/discussion')}
            >
              <Logo src={`Vector.png`} className={classes.root} alt="vector" />
              <Span className={classes.text}>
                {detail?.totalOpenTickets} Open
                {detail?.totalOpenTickets < 2 ? ' Discussion' : ' Discussions'}
              </Span>
            </Div>
            <Div
              variant={'body1'}
              className={classes.cardContentText}
              onClick={() => navigate('/discussion')}
            >
              <Logo src={`Vector.png`} className={classes.root} alt="vector" />
              <Span className={classes.text}>
                {detail?.totalClosedTickets} Closed
                {detail?.totalClosedTickets < 2 ? ' Discussion' : ' Discussions'}
              </Span>
            </Div>
            <Div
              variant={'body1'}
              className={classes.cardContentText}
              onClick={() => navigate('/feedback')}
            >
              <Logo src={`Vector.png`} className={classes.root} alt="vector" />
              <Span className={classes.text}>
                Stage {step} feedback form is active
              </Span>
            </Div>
          </Grid>
          {!isMobile && (
            <>
              <Grid item xs={12} md={4} lg={4}>
                <OverallProjectStatus progress={detail?.overallProgress} />
              </Grid>
              <Grid item xs={12} md={4} lg={4}>
                <ProjectCompletionDate
                  date={detail?.projectCompletionDate}
                  days={detail?.remainingDays}
                  isExceed={detail?.isExceed}
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WelcomeClient;
