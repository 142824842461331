import React from 'react';
import TimeAgo from 'javascript-time-ago';
import { useNavigate } from 'react-router-dom';
import en from 'javascript-time-ago/locale/en';
import { useDispatch } from 'react-redux';

import Avatar from '@mui/material/Avatar';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import Span from '@jumbo/shared/Span';
import {
  getUnReadNotifications,
  updateNotificationStatus,
} from 'app/redux/actions/Notification';

TimeAgo.addDefaultLocale(en);
const Notification = ({ item }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const timeAgo = new TimeAgo('en-US');
  const changeStatus = async () => {
    if (item?.redirectUrl) navigate(item?.redirectUrl);
    await dispatch(updateNotificationStatus(item._id));
    await dispatch(getUnReadNotifications());
  };
  return (
    <ListItemButton
      component={'li'}
      alignItems={'flex-start'}
      onClick={changeStatus}
    >
      <ListItemAvatar>
        <Avatar src={''} sx={{ background: '#0068b3' }} />
      </ListItemAvatar>
      <ListItemText>
        {item.body}
        <Typography
          component="span"
          sx={{
            display: 'flex',
            fontSize: '90%',
            mt: 0.5,
          }}
        >
          <Span sx={{ color: 'text.secondary', ml: 1 }}>
            {timeAgo.format(new Date(item?.createdAt))}
          </Span>
        </Typography>
      </ListItemText>
    </ListItemButton>
  );
};

export default Notification;
