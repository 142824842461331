import axios from 'axios';

import {
  SET_USER_INFORMATION,
  REMOVE_USER_INFORMATION,
} from '../../utils/constants/constant';

export const url = process.env.REACT_APP_API_BASEURL_AUTH;

export const signIn = (data) => {
  return (dispatch, getState) => {
    return axios
      .post(`${url}login`, data)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: SET_USER_INFORMATION,
            payload: response?.data?.data?.user,
          });
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMessage });
      });
  };
};

export const signOut = (id) => {
  return (dispatch) => {
    return axios.post(`${url}logout/${id}`).then((response) => {
      var d = new Date();
      d.setTime(d.getTime() + 0);
      var expires = 'expires=' + d.toUTCString();
      document.cookie = 'access_token= ;' + expires + ';path=/';
      document.cookie = '_csrf= ;' + expires + ';path=/';
      dispatch({
        type: REMOVE_USER_INFORMATION,
      });
    });
  };
};

export const getById = (id) => {
  return (dispatch, getState) => {
    return axios
      .get(`user/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const updateInformation = (data) => {
  return (dispatch, getState) => {
    return axios
      .put(`user/${data?._id}`, data)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: SET_USER_INFORMATION,
            payload: response?.data?.data?.user,
          });
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMessage });
      });
  };
};

export const removeUserInformation = () => {
  return (dispatch) => {
    var d = new Date();
    d.setTime(d.getTime() + 0);
    var expires = 'expires=' + d.toUTCString();
    document.cookie = 'access_token= ;' + expires + ';path=/';
    document.cookie = '_csrf= ;' + expires + ';path=/';
    dispatch({
      type: REMOVE_USER_INFORMATION,
    });
  };
};
export const forgetPassword = (data) => {
  return (dispatch, getState) => {
    return axios
      .post(`${url}/forgot-password`, { ...data })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const resetPassword = (data) => {
  return (dispatch, getState) => {
    return axios
      .post(`${url}/reset-password`, { ...data })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
