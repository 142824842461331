import axios from 'axios';

import {
  GET_DISCUSSION_LIST,
  VIEW_DISCUSSION_LIST,
  REMOVE_DISCUSSION_LIST,
} from '../../utils/constants/constant';

export const getDiscussionTopicList = (data) => {
  return (dispatch, getState) => {
    return axios
      .get(`discussion-topic/list`, { params: { ...data } })
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const saveDiscussion = (data) => {
  return (dispatch, getState) => {
    return axios
      .post(`discussion`, data)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMessage });
      });
  };
};
export const getDiscussionList = (data) => {
  return (dispatch, getState) => {
    return axios
      .get(`discussion/list`, { params: { ...data } })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_DISCUSSION_LIST,
            payload: response?.data?.data?.ticketList,
          });
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const viewDiscussionList = (discussionId) => {
  return (dispatch, getState) => {
    return axios
      .get(`discussion/${discussionId}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: VIEW_DISCUSSION_LIST,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const replyDiscussion = (data) => {
  return (dispatch, getState) => {
    return axios
      .post(`discussion/reply`, data)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMessage });
      });
  };
};

export const closedDiscussion = (data) => {
  return (dispatch, getState) => {
    return axios
      .put(`discussion/${data?.id}`, data)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMessage });
      });
  };
};

export const removeDiscussionData = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_DISCUSSION_LIST,
    });
  };
};
