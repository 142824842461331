import {
  GET_FEEDBACK_LIST,
  REMOVE_FEEDBACK_LIST,
} from '../../utils/constants/constant';

const INIT_STATE = {
  feedbackList: [],
};

const reducerFunc = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FEEDBACK_LIST: {
      return {
        ...state,
        feedbackList: action.payload,
      };
    }
    case REMOVE_FEEDBACK_LIST: {
      return {
        ...INIT_STATE,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducerFunc;
