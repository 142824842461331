import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Avatar from '@mui/material/Avatar';
import {
  ListItemIcon,
  ListItemText,
  ThemeProvider,
  Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import JumboDdPopover from '@jumbo/components/JumboDdPopover';
import Div from '@jumbo/shared/Div';
import useJumboTheme from '@jumbo/hooks/useJumboTheme';
import { signOut } from 'app/redux/actions/auth';
import { removeProjectData } from 'app/redux/actions/project';
import { removeDashboardData } from 'app/redux/actions/dashboard';
import { removeDiscussionData } from 'app/redux/actions/discussion';
import { removeFeedbackData } from 'app/redux/actions/feedback';
import { removePhaseData } from 'app/redux/actions/phase';
import { removeReportData } from 'app/redux/actions/report';
import { removeWorkScheduleData } from 'app/redux/actions/workschedule';

const AuthUserDropdown = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { theme } = useJumboTheme();
  const { authUserInformation } = useSelector(
    ({ userInformation }) => userInformation,
  );
  const onLogout = () => {
    dispatch(signOut(authUserInformation?._id));
    dispatch(removeProjectData());
    dispatch(removeDashboardData());
    dispatch(removeDiscussionData());
    dispatch(removeFeedbackData());
    dispatch(removePhaseData());
    dispatch(removeReportData());
    dispatch(removeWorkScheduleData());
    navigate('/login', { replace: true });
  };
  const profileNavigate = () => {
    navigate('/profile');
  };
  return (
    <ThemeProvider theme={theme}>
      <JumboDdPopover
        triggerButton={
          <Avatar
            src={''}
            sizes={'small'}
            sx={{ boxShadow: 25, cursor: 'pointer', background: '#0068b3' }}
          />
        }
      >
        <Div
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            p: (theme) => theme.spacing(2.5),
          }}
        >
          <Avatar
            src={''}
            alt={authUserInformation?.name}
            sx={{ width: 60, height: 60, mb: 2, background: '#0068b3' }}
          />
          <Typography variant={'h5'}>{authUserInformation?.name}</Typography>
          <Typography variant={'body1'} color="text.secondary">
            {authUserInformation?.email}
          </Typography>
        </Div>
        <Divider />
        <nav>
          <List disablePadding sx={{ pb: 1 }}>
            <ListItemButton onClick={profileNavigate}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <EditOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="Edit Profile" sx={{ my: 0 }} />
            </ListItemButton>
            <ListItemButton onClick={onLogout}>
              <ListItemIcon sx={{ minWidth: 36 }}>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" sx={{ my: 0 }} />
            </ListItemButton>
          </List>
        </nav>
      </JumboDdPopover>
    </ThemeProvider>
  );
};

export default AuthUserDropdown;
