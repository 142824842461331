import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import { Grid, Tooltip, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { makeStyles } from '@mui/styles';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AccessTimeFilledOutlinedIcon from '@mui/icons-material/AccessTimeFilledOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import JumboAppTextField from '@jumbo/components/JumboAppTextField/JumboAppTextField';
import Div from '@jumbo/shared/Div';
import AvatarList from '../Avatar';
import {
  replyDiscussion,
  viewDiscussionList,
  closedDiscussion,
} from 'app/redux/actions/discussion';
import { uploadFileS3, uploadFileS3signedUrl } from 'app/redux/actions/aws';
import JumboButton from '@jumbo/components/JumboButton/JumboButton';
import DropzoneComponent from '../Dropzone';

const useStyles = makeStyles((theme) => ({
  padding: {
    padding: '15px',
  },
  rootBtn: {
    height: '55px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      height: '40px',
    },
  },
  root: {
    display: 'flex',
    padding: '15px',
    margin: '10px',
    borderRadius: '11Px',
    position: 'relative',
  },
  icon: {
    verticalAlign: 'middle',
    fontSize: '1rem',
    marginTop: -0.25,
    marginRight: 1,
  },
  attachIcon: {
    position: 'absolute',
    top: '10px',
    right: '5px',
    cursor: 'pointer',
  },
  avatar: {
    width: '40px',
    height: '40px',
    background: '#0068b3',
  },
  marginLeft: {
    marginLeft: 10,
  },
  textFieldMargin: {
    marginTop: 22,
  },
  actionButton: {
    width: '100%',
  },
}));

const DiscussionChat = ({ id }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { viewDiscussion } = useSelector(({ discussion }) => discussion);
  const [isShow, setIsShow] = useState(false);
  const [value, setValue] = useState({
    attachment: '',
    previewAttachment: '',
    comment: '',
  });
  const [errorText, setErrorText] = React.useState({});
  const getMessageList = () => {
    dispatch(viewDiscussionList(id));
  };
  useEffect(() => {
    getMessageList();
  }, []);
  const validate = () => {
    const errors = {};
    let isError = false;
    if (!value?.comment) {
      errors.comment = 'Please enter comment.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  const replyThread = async () => {
    let validateData = validate();
    if (!validateData.isError) {
      const response = await dispatch(
        replyDiscussion({
          ticketId: id,
          comment: value.comment,
          attachment: value.attachment,
        }),
      );
      if (response.status === 200) {
        setValue({
          attachment: '',
          previewAttachment: null,
          comment: '',
        });
        enqueueSnackbar(response.data.responseMessage, {
          variant: 'success',
        });
        getMessageList();
      } else {
        enqueueSnackbar(response.error, {
          variant: 'error',
        });
      }
    }
  };
  const onUpload = async (file) => {
    if (file[0] && file[0].size <= 2097152) {
      const response = await dispatch(
        uploadFileS3signedUrl({
          key: `${Date.now()}-${file[0].name.replace(' ', '')}`,
          ContentType: file[0].type,
          folderName: process.env.REACT_APP_FOLDER_NAME_DISCUSSION,
        }),
      );

      if (response.status === 200) {
        setValue({
          ...value,
          attachment: response?.data?.data?.url,
          previewAttachment: file[0]?.preview,
        });
        await dispatch(
          uploadFileS3({
            url: response.data.data.signedUrl,
            data: file[0],
          }),
        );
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar('The document may not be greater than 2 MB', {
        variant: 'error',
      });
    }
  };
  const onSubmit = async () => {
    const response = await dispatch(closedDiscussion({ id, status: false }));
    if (response.status === 200) {
      enqueueSnackbar(response.data.responseMessage, {
        variant: 'success',
      });
      setIsShow(false);
      getMessageList();
    } else {
      enqueueSnackbar(response.error, {
        variant: 'error',
      });
    }
  };
  return (
    <>
      <Grid
        container
        columnSpacing={1}
        rowSpacing={2}
        className={classes.padding}
      >
        <Grid item xs={10} sm={11}>
          <Typography variant={'h5'} mb={0.5}>
            {`Discussion No. - ${viewDiscussion?.ticketStatus?.ticketNumber}`}
          </Typography>
          <Typography variant={'h5'} mb={0.5}>
            {`Discussion Category - ${viewDiscussion?.ticketStatus?.topicId?.name}`}
          </Typography>
          <Typography variant={'h5'} mb={0.5} className="discussion-title">
            {`Discussion Title - ${viewDiscussion?.ticketStatus?.ticketTitle}`}
          </Typography>
        </Grid>
        <Grid item xs={2} sm={1}>
          <Tooltip title="Back" placement="top-start" arrow>
            <Div
              style={{
                width: '40px',
                height: '40px',
                marginLeft: 'auto',
                borderRadius: '50%',
                backgroundColor: '#BDD52F',
              }}
              onClick={() => navigate('/discussion')}
            >
              <ArrowBackIcon style={{ width: '22px', margin: '9px' }} />
            </Div>
          </Tooltip>
        </Grid>
      </Grid>
      {viewDiscussion?.discussionOnTicket?.map((element, index) => {
        const { userId, attachment, comment, createdAt } = element;
        const time = moment(createdAt).format('HH:mm:ss');
        const date = moment(createdAt).format('DD-MM-YYYY');
        return (
          <Div
            className={classes.root}
            key={index}
            sx={{
              background: index % 2 === 0 ? '#fef7e1' : '#f4f4f4',
            }}
          >
            <AvatarList
              alt={userId?.name}
              icon={element.img}
              className={classes.avatar}
            />
            <Div className={classes.marginLeft}>
              <Typography variant={'h5'} mb={0.25}>
                {userId?.name}
              </Typography>
              <Typography
                variant={'body1'}
                color={'text.secondary'}
                fontSize={'12px'}
                mb={2}
              >
                <AccessTimeFilledOutlinedIcon className={classes.icon} />
                {time} &nbsp;
                <CalendarTodayOutlinedIcon className={classes.icon} />
                {date}
              </Typography>
              {attachment && (
                <Tooltip title="View Attachment" placement="top-start" arrow>
                  <Div
                    className={classes.attachIcon}
                    onClick={() => window.open(attachment, '_blank')}
                  >
                    <AttachFileIcon />
                  </Div>
                </Tooltip>
              )}
              <Typography
                className="discussion-title"
                variant={'body1'}
                color={'text.secondary'}
                fontSize={'14px'}
                mb={2}
              >
                {comment}
              </Typography>
            </Div>
          </Div>
        );
      })}
      {viewDiscussion?.ticketStatus?.status && (
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          className={classes.padding}
        >
          <Grid item xs={12} md={6} lg={2}>
            <JumboButton
              className={classes.actionButton}
              variant="contained"
              size="small"
              onClick={() => setIsShow(true)}
            >
              Reply
            </JumboButton>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <JumboButton
              className={classes.actionButton}
              color="search"
              variant="contained"
              size="small"
              onClick={() => onSubmit(false)}
            >
              Close Discussion
            </JumboButton>
          </Grid>
        </Grid>
      )}
      {isShow && (
        <Grid
          container
          columnSpacing={1}
          rowSpacing={2}
          className={classes.padding}
        >
          <Grid item xs={12} md={6}>
            <JumboAppTextField
              className={classes.textFieldMargin}
              name="comment"
              type="text"
              label="Add reply*"
              size="normal"
              value={value?.comment}
              onChange={(event) =>
                setValue({ ...value, comment: event.target.value })
              }
              error={errorText.comment}
              helperText={errorText.comment}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography variant="body1">Attachment</Typography>
            <DropzoneComponent
              isMinHeight={true}
              sx={{ minHeight: '55px' }}
              onDropFile={(files) => onUpload(files)}
              files={
                value?.attachment
                  ? [
                      {
                        preview: value?.previewAttachment,
                        fileName: value?.attachment,
                      },
                    ]
                  : []
              }
              onRemove={() =>
                setValue({
                  ...value,
                  attachment: null,
                  previewAttachment: null,
                })
              }
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Div className={classes.textFieldMargin}>
              <JumboButton
                className={classes.rootBtn}
                onClick={replyThread}
                variant={'contained'}
                size="medium"
              >
                Send
              </JumboButton>
            </Div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DiscussionChat;
