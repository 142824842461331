import React from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';

const JumboAppTextField = ({
  type,
  name,
  id,
  fullWidth,
  value,
  onChange,
  helperText,
  variant,
  error,
  size,
  InputLabelProps,
  ...rest
}) => {
  return (
    <TextField
      type={type}
      name={name}
      id={id || name}
      size={size}
      fullWidth={fullWidth}
      value={value}
      variant={variant}
      onChange={onChange}
      error={error || helperText !== ''}
      helperText={helperText}
      InputLabelProps={InputLabelProps}
      {...rest}
    />
  );
};
JumboAppTextField.prototype = {
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
};

JumboAppTextField.defaultProps = {
  type: 'text',
  fullWidth: true,
  size: 'small',
  error: false,
  helperText: '',
  variant: 'outlined',
};

export default JumboAppTextField;
