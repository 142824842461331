import React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const BasicChips = ({ variant, label, color }) => {
  return (
    <Stack direction="row" spacing={1}>
      <Chip color={color} label={label} variant={variant} />
    </Stack>
  );
};
export default BasicChips;
