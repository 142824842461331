import React from 'react';

import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import JumboAppTextField from '@jumbo/components/JumboAppTextField/JumboAppTextField';
import RatingComponent from '../Rating';

const useStyles = makeStyles((theme) => ({
  stepper: {
    background: 'none',
  },
  buttonAction: {
    display: 'flex',
    marginLeft: '12px',
    paddingLeft: '20px',
    paddingRight: '8px',
    marginTop: '30px',
  },
  marginTop: {
    marginTop: 30,
  },
}));
const StepperComponent = ({
  orientation,
  data,
  active,
  handleChange,
  isEditAllow,
  errorText,
  handleChangeRating,
}) => {
  const classes = useStyles();
  const color = ['#0068B3', '#BDD52F', '#4DADF1', '#ff390c99', '#BDD52F'];
  return (
    <Box>
      <Stepper orientation={orientation} className={classes.stepper}>
        {data.map((step, index) => (
          <Step active={active} key={step?.title}>
            <StepLabel
              sx={{
                '.MuiStepLabel-iconContainer': {
                  '& .MuiStepIcon-root': {
                    color: color[index],
                  },
                  '& .MuiStepIcon-text': {
                    display: 'none',
                  },
                },
              }}
            >
              <Grid container>
                <Grid item xs={12} md={8}>
                  {step?.title}
                </Grid>
              </Grid>
            </StepLabel>
            {step.data.map((val, i) => (
              <StepContent key={val?.question}>
                <Grid container mb={1}>
                  <Grid item xs={12} md={10}>
                    <Typography>{val?.question}</Typography>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    {step?.title !== 'Note' && (
                      <RatingComponent
                        value={val?.answer?.rating}
                        disabled={!isEditAllow}
                        onChange={(event, newValue) =>
                          handleChangeRating(newValue, index,i)
                        }
                      />
                    )}
                  </Grid>
                </Grid>
                {(step?.title === 'Note' ||
                  (val?.answer?.rating <= 3 && val?.answer?.rating > 0)) && (
                  <JumboAppTextField
                    disabled={!isEditAllow}
                    className={classes.marginTop}
                    multiline
                    rows={4}
                    label="Remark*"
                    name="remark"
                    onChange={(event) => handleChange(event, index,i)}
                    value={val?.answer?.remark}
                    error={errorText?.[index]?.data?.[i]?.remark}
                    helperText={errorText?.[index]?.data?.[i]?.remark}
                  />
                )}
              </StepContent>
            ))}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
export default StepperComponent;
