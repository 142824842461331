import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { forgetPassword } from 'app/redux/actions/auth';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { ROUTES } from 'app/utils/constants/routes';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { useIsTabDevice } from 'app/hooks/isMobile';
import { getAssetPath } from '../../../app/utils/appHelpers';
import { emailPatten } from '@jumbo/utils/validation';
import JumboTextField from '@jumbo/components/JumboAppTextField';
import Div from '@jumbo/shared/Div';
import JumboButton from '@jumbo/components/JumboButton/JumboButton';
import Logo from 'app/shared/Logo/Logo';
import CardComponent from '@jumbo/components/common/Card';
import Footer from 'app/layouts/shared/footers/Footer';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: 24,
    '&:last-child': {
      paddingBottom: '18px',
    },
  },
  logoContainer: {
    position: 'relative',
    textAlign: 'right',
    right: '50px',
    top: '50px',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '-22px',
      right: '-25px',
      width: '50px',
      height: '50px',
      background: `url(${getAssetPath(
        `${ASSET_IMAGES}/Pidilite-Disha-logo.png`,
      )}) no-repeat center`,
    },
  },
  fontSize: {
    fontSize: '30px',
    color: theme.palette.info.main,
  },
  mainContainer: {
    position: 'relative',
    display: 'block',
    height: '100vh',
    background: `url(${getAssetPath(
      `${ASSET_IMAGES}/Pidilite-Disha.jpg`,
    )}) no-repeat center`,
    backgroundSize: 'cover',
  },
  forgetForm: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    textAlign: 'center',
    padding: '40px',
  },
  brandImage: {
    width: '200px',
    margin: '0 auto 40px',
  },
  submitBtn: {
    width: '100%',
    marginTop: 16,
  },
  footer: {
    width: '100%',
  },
}));
const ForgetPassword = ({ disableSmLogin }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['access_token']);
  const [errorText, setErrorText] = useState({});
  const [email, setEmail] = useState('');
  const { isTabDevice } = useIsTabDevice();
  const validate = () => {
    const errors = {};
    let isError = false;
    if (!email) {
      errors.email = 'Please enter email.';
      isError = true;
    } else if (emailPatten(email)) {
      errors.email = 'Invalid email address.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  const onForgetPassword = async () => {
    let validateData = validate();
    if (!validateData.isError) {
      const response = await dispatch(
        forgetPassword({ email, isClientUser: true }),
      );
      setEmail('');
      if (response.status === 200) {
        enqueueSnackbar(response.data.responseMessage, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(response.error, {
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {}, []);
  return (
    <>
      <Grid container rowSpacing={5} direction="row" className="login-wrapper">
        {!isTabDevice && (
          <Grid item lg={8} xl={8} md={12} xs={12}>
            <Div className={classes.mainContainer}>
              <Div className={classes.logoContainer}>
                <Typography className={classes.fontSize} variant={'body1'}>
                  BUILDING TRUST
                </Typography>
                <Typography className={classes.fontSize} variant={'body1'}>
                  WITH TRANSPARENCY
                </Typography>
              </Div>
            </Div>
          </Grid>
        )}
        <Grid item xl={4} md={12} xs={12} lg={4}>
          <Div className={classes.forgetForm}>
            <Logo className={classes.brandImage} src="disha_logo.png" />
            <CardComponent
              title="Forget Password"
              className={classes.cardContent}
            >
              <JumboTextField
                type="text"
                label="Email*"
                name="email"
                size="normal"
                margin="normal"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                error={errorText.email}
                helperText={errorText.email}
              />
              <Typography textAlign={'right'} variant={'body1'} mt={1}>
                <Link underline="none" to={ROUTES.LOGIN}>
                  Down back to login?
                </Link>
              </Typography>
              <JumboButton
                className={classes.submitBtn}
                color="primary"
                variant="contained"
                size="medium"
                onClick={onForgetPassword}
              >
                Submit
              </JumboButton>
            </CardComponent>
          </Div>
        </Grid>
      </Grid>
      <Footer className={classes.footer} />
    </>
  );
};

export default ForgetPassword;
