import axios from 'axios';

import {
  GET_NOTIFICATION,
  GET_UNREAD_NOTIFICATION,
} from '../../utils/constants/constant';
export const url = process.env.REACT_APP_API_BASE_URL;

export const getNotifications = ({ type, page, perPage }) => {
  return (dispatch) => {
    return axios
      .get(`${url}notification/list`, { params: { type, page, perPage } })
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_NOTIFICATION,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  };
};

export const getUnReadNotifications = () => {
  return (dispatch) => {
    return axios
      .get(`${url}notification/list`)
      .then((response) => {
        if (response?.status === 200) {
          dispatch({
            type: GET_UNREAD_NOTIFICATION,
            payload: response?.data?.data,
          });
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        return Promise.resolve({ error: err.response.data.responseMessage });
      });
  };
};

export const updateNotificationStatus = (id) => {
  return (dispatch) => {
    return axios
      .put(`${url}notification/${id}`)
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        return Promise.resolve({ error: err.response.data.responseMessage });
      });
  };
};
