import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { getTitle } from 'app/utils/appHelpers';
import { makeStyles } from '@mui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useIsMobileDevice } from 'app/hooks/isMobile';
import { viewReportRoute } from 'app/utils/constants/routes';
import Div from '@jumbo/shared/Div/Div';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import CardComponent from '@jumbo/components/common/Card';
import Accordion from '@jumbo/components/common/Accordion';
import JumboDdMenu from '@jumbo/components/JumboDdMenu/JumboDdMenu';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FormDialog from '@jumbo/components/common/Dialog';
import JumboAppTextField from '@jumbo/components/JumboAppTextField/JumboAppTextField';
import Avatar from '@jumbo/components/common/Avatar';
import moment from 'moment/moment';
import { useEffect } from 'react';
import {
  getOldReports,
  addRemark,
  updateOldReportList,
} from 'app/redux/actions/report';
import { Tooltip } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  avatarIcon: {
    width: 40,
    height: 40,
  },
}));
const OldReport = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { reportId, phaseId, categoryId } = useParams();
  const { isMobile } = useIsMobileDevice();
  const [errorText, setErrorText] = React.useState({});
  const [open, setOpen] = useState(false);
  const [report, setReport] = useState();
  const [remark, setRemark] = useState('');
  const [oldReportId, setOldReportId] = useState(null);
  const [remarkFlag, setRemarkFlag] = useState(false);
  const handleItemAction = (menuItem, record) => {
    // eslint-disable-next-line default-case
    switch (menuItem.action) {
      case 'addRemark':
        setErrorText({});
        setOldReportId(record?._id);
        setRemarkFlag(record?.remark ? true : false);
        if (record?.remark) {
          setRemark(record?.remark);
        } else {
          setRemark('');
        }
        setOpen(!open);
        break;
      case 'downloadReport':
        window.open(record?.attachment, '_blank');
        break;
    }
  };
  const columns = [
    {
      label: ' ',
      key: 'icon',
      render: (elm) => (
        <>
          <Avatar
            className={classes.avatarIcon}
            icon={elm?.attachmentExt === '.pdf' ? 'pdfImg.png' : ''}
          />
        </>
      ),
    },
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Category',
      render: (elm) => <>{elm?.categoryId?.name}</>,
    },
    {
      label: 'Phase Name',
      render: (elm) => (
        <>{elm?.phaseId?.name ? elm?.phaseId?.name : 'Common'}</>
      ),
    },
    {
      label: 'Uploaded On',
      key: 'createdAt',
      render: (elm) => <>{moment(elm?.createdAt).format('DD-MM-YYYY')}</>,
    },
    {
      label: 'Remarks',
      key: 'remark',
      ellipsis: true,
    },
    {
      label: 'Comment',
      key: 'description',
      ellipsis: true,
    },
    {
      label: ' ',
      key: 'actionIcon',
      render: (elm) => {
        const menuItems = [
          {
            icon: <FileDownloadIcon />,
            title: elm?.remark ? 'Edit Remark' : 'Add Remark',
            action: 'addRemark',
          },
        ];
        if (elm?.attachment) {
          menuItems.push({
            icon: <VisibilityIcon />,
            title: 'View Report',
            action: 'downloadReport',
          });
        }
        return (
          <JumboDdMenu
            icon={<MoreHorizIcon />}
            menuItems={menuItems}
            onClickCallback={(event) => handleItemAction(event, elm)}
          />
        );
      },
    },
  ];
  const handleChange = (event) => {
    setRemark(event.target.value);
  };
  const validate = () => {
    const errors = {};
    let isError = false;
    if (!remark) {
      errors.remark = 'Please enter remark.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  useEffect(async() => {
    const obj = { reportId, categoryId };
    if (phaseId === 'undefined') {
      obj.isGeneral = true;
    } else {
      obj.phaseId = phaseId;
    }
    const response =await dispatch(getOldReports(obj));
    if (response.status === 200) {
      setReport(response.data.data);
    }
    
  }, []);
  const handleSubmit = async () => {
    let validateData = validate();
    if (!validateData.isError) {
      const response = await dispatch(
        addRemark({ reportId: oldReportId, remark, isOldReport: true }),
      );
      if (response.status === 200) {
        const { remarkAdd } = response.data.data;
        const updateReport = report?.oldReportsList.map((single) =>
          single?._id === oldReportId ? remarkAdd : single,
        );
        enqueueSnackbar(response.data.responseMessage, {
          variant: 'success',
        });
        dispatch(updateOldReportList(updateReport));
        setOpen(!open);
      } else {
        enqueueSnackbar(response.error, {
          variant: 'error',
        });
      }
    }
  };
  return (
    <>
      {isMobile ? (
        <PageHeader
          title={getTitle(viewReportRoute(reportId, categoryId, phaseId))}
        />
      ) : (
        ''
      )}
      <Div>
        <CardComponent title={`Old Reports for "${report?.phaseName ?? 'Common Phase'}" in "${report?.categoryName}"`}>
          <Accordion
            columns={columns}
            record={report?.oldReportsList}
            mobileTitleKey={['name']}
          />
        </CardComponent>
      </Div>
      <br></br>
      <Tooltip title="Back" placement="top-start" arrow>
        <Div
          style={{
            width: '40px',
            height: '40px',
            marginLeft: 'auto',
            borderRadius: '50%',
            backgroundColor: '#BDD52F',
          }}
          onClick={() => navigate('/report')}
        >
          <ArrowBackIcon style={{ width: '22px', margin: '9px' }} />
        </Div>
      </Tooltip>
      <Div>
        <FormDialog
          open={open}
          maxWidth={'md'}
          title={remarkFlag ? 'Edit Remark' : 'Add Remark'}
          handleClose={() => {
            setRemark('');
            setOpen(!open);
          }}
          handleSubmit={() => handleSubmit()}
          confirmButtonText="Submit"
          cancelButtonText="Cancel"
        >
          <JumboAppTextField
            multiline
            rows={4}
            label="Remark*"
            name="remark"
            onChange={handleChange}
            value={remark}
            error={errorText.remark}
            helperText={errorText.remark}
          />
        </FormDialog>
      </Div>
    </>
  );
};
export default OldReport;
