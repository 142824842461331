import React from 'react';

import TocIcon from '@mui/icons-material/Toc';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonAddAltSharpIcon from '@mui/icons-material/PersonAddAltSharp';
import ChatIcon from '@mui/icons-material/Chat';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import { ROUTES, TITLES_BY_ROUTE } from 'app/utils/constants/routes';

const menus = [
  {
    label: ' ',
    type: 'section',
    children: [
      {
        uri: ROUTES.DASHBOARD,
        label: TITLES_BY_ROUTE.DASHBOARD,
        type: 'nav-item',
        icon: <DashboardOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: ROUTES.WORKSCHEDULE,
        label: TITLES_BY_ROUTE.WORKSCHEDULE,
        type: 'nav-item',
        icon: <PendingActionsIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: ROUTES.REPORTS,
        label: TITLES_BY_ROUTE.REPORTS,
        type: 'nav-item',
        icon: <TocIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: ROUTES.DISCUSSION,
        label: TITLES_BY_ROUTE.DISCUSSION,
        type: 'nav-item',
        icon: <ChatIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: ROUTES.FEEDBACK,
        label: TITLES_BY_ROUTE.FEEDBACKMENU,
        type: 'nav-item',
        icon: <PersonAddAltSharpIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: ROUTES.PROJECT,
        label: TITLES_BY_ROUTE.PROJECT,
        type: 'nav-item',
        icon: <AccountCircleIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: ROUTES.NOTIFICATION,
        label: 'Notification',
        type: 'nav-item',
        icon: <NotificationsNoneOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
