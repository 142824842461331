import React, { Suspense, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { IconButton, Typography } from '@mui/material';
import useAuth from 'app/hooks/isAuth';
import { makeStyles } from '@mui/styles';
import { useIsMobileDevice } from 'app/hooks/isMobile';
import JumboVerticalNavbar from '@jumbo/components/JumboVerticalNavbar/JumboVerticalNavbar';
import { DrawerHeader } from '@jumbo/components/JumboLayout/style';
import JumboScrollbar from '@jumbo/components/JumboScrollbar';
import useJumboLayoutSidebar from '@jumbo/hooks/useJumboLayoutSidebar';
import useJumboSidebarTheme from '@jumbo/hooks/useJumboSidebarTheme';
import { SIDEBAR_STYLES, SIDEBAR_VIEWS } from '@jumbo/utils/constants/layout';
import Logo from 'app/shared/Logo/Logo';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Zoom from '@mui/material/Zoom';
import Div from '@jumbo/shared/Div';
import SidebarSkeleton from './SidebarSkeleton';
import menus from './menus';
import { ROUTES } from 'app/utils/constants/routes';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '110px',
  },
  imgWidth: {
    width: '50px',
  },
}));
const Sidebar = () => {
  const location = useLocation();
  const authUserInformation = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const { sidebarTheme } = useJumboSidebarTheme();

  const isMiniAndClosed = React.useMemo(() => {
    return sidebarOptions?.view === SIDEBAR_VIEWS.MINI && !sidebarOptions?.open;
  }, [sidebarOptions.view, sidebarOptions.open]);
  useEffect(() => {
    if (authUserInformation && location.pathname === ROUTES.LOGIN) {
      navigate(ROUTES.DASHBOARD);
    }
  }, []);
  return (
    <React.Fragment>
      <SidebarHeader
        setSidebarOptions={setSidebarOptions}
        sidebarOptions={sidebarOptions}
        sidebarTheme={sidebarTheme}
        isMiniAndClosed={isMiniAndClosed}
      />
      <JumboScrollbar autoHide autoHideDuration={200} autoHideTimeout={500}>
        <Suspense
          fallback={
            <Div
              sx={{
                display: 'flex',
                minWidth: 0,
                alignItems: 'center',
                alignContent: 'center',
                px: 3,
              }}
            >
              <SidebarSkeleton />
            </Div>
          }
        >
          <JumboVerticalNavbar translate items={menus} />
        </Suspense>
      </JumboScrollbar>
      <Div sx={{ textAlign: 'center' }} justifyContent="center">
        <Typography variant={'h5'} mb={1}>
          An initiative by
        </Typography>
        <Div sx={{ mt: 'auto' }}>
          <Logo
            src={`pidilite_footer.png`}
            mini={isMiniAndClosed}
            mode={sidebarTheme.type}
            className={classes.root}
          />
        </Div>
      </Div>
    </React.Fragment>
  );
};

const SidebarHeader = ({
  isMiniAndClosed,
  setSidebarOptions,
  sidebarOptions,
  sidebarTheme,
}) => {
  const { isMobile } = useIsMobileDevice();
  const classes = useStyles();
  return (
    <React.Fragment>
      {sidebarOptions?.style !== SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <DrawerHeader>
          <Logo
            src={isMobile ? `mobile-logo-disha.png` : `disha_logo.png`}
            mode={sidebarTheme.type}
            className={isMobile ? classes.imgWidth : classes.root}
          />
          {sidebarOptions?.view !== SIDEBAR_VIEWS.MINI && (
            <Zoom in={sidebarOptions?.open}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                sx={{ ml: 0, mr: -1.5 }}
                onClick={() => setSidebarOptions({ open: false })}
              >
                <MenuOpenIcon />
              </IconButton>
            </Zoom>
          )}
        </DrawerHeader>
      )}
    </React.Fragment>
  );
};

export default Sidebar;
