import {
  GET_NOTIFICATION,
  GET_UNREAD_NOTIFICATION,
} from '../../utils/constants/constant';

const INIT_STATE = {
  totalItems: 0,
  perPage: 0,
  list: [],
  unreadList: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NOTIFICATION: {
      return {
        ...state,
        list: action?.payload?.notificationList,
        totalItems: action?.payload?.total,
        perPage: action?.payload?.perPage,
      };
    }
    case GET_UNREAD_NOTIFICATION: {
      return {
        ...state,
        unreadList: action?.payload?.notificationList,
      };
    }
    default: {
      return state;
    }
  }
};
