import React from 'react';

import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

const RatingComponent = ({ value, onChange, name, disabled }) => {
  return (
    <Stack spacing={1}>
      <Rating
        onChange={onChange}
        defaultValue={value}
        precision={0.5}
        disabled={disabled}
      />
    </Stack>
  );
};
export default RatingComponent;
