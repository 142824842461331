import React from 'react';

import { Avatar } from '@mui/material';
import { ASSET_IMAGES } from 'app/utils/constants/paths';

const AvatarList = ({ icon, alt, className }) => {
  return (
    <Avatar className={className} src={`${ASSET_IMAGES}/${icon}`} alt={alt} />
  );
};
export default AvatarList;
