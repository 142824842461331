import axios from 'axios';

const Url = process.env.REACT_APP_API_AWS_URL;
const awsUrl = process.env.REACT_APP_API_BASE_URL;

export const uploadImage = (formData) => {
  return (dispatch, getState) => {
    return axios
      .post(`${Url}/upload`, formData)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMsg });
      });
  };
};

export const uploadFileS3signedUrl = ({ key, ContentType, folderName }) => {
  return (dispatch) => {
    return axios
      .get(`${awsUrl}aws/signed-url`, { params: { key, ContentType, folderName } })
      .then((response) => {
        if (response?.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((err) => {
        return Promise.resolve(err);
      });
  };
};

export const uploadFileS3 = ({ url, data }) => {
  return (dispatch) => {
    const xhr = new XMLHttpRequest();
    xhr.open('PUT', url);
    xhr.setRequestHeader('Content-Type', data.type);
    // xhr.setRequestHeader('x-amz-acl', 'public-read');
    xhr.addEventListener('readystatechange', function () {
      if (this.readyState === 4) {
        return Promise.resolve(xhr.response);
      }
    });
    xhr.send(data);
  };
};
