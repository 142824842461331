import React from 'react';
import { useParams } from 'react-router-dom';

import { useIsMobileDevice } from 'app/hooks/isMobile';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import { getTitle } from 'app/utils/appHelpers';
import { viewDiscussionRoute } from 'app/utils/constants/routes';
import { makeStyles } from '@mui/styles';
import Card from '@jumbo/components/common/Card';
import DiscussionChat from '@jumbo/components/common/DiscussionChat';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: 24,
    '&:last-child': {
      paddingBottom: '18px',
    },
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
}));

const ViewDiscussion = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { isMobile } = useIsMobileDevice();
  return (
    <>
      {isMobile && <PageHeader title={getTitle(viewDiscussionRoute(id))} />}
      <Card className={classes.cardContent}>
        <DiscussionChat id={id} />
      </Card>
    </>
  );
};
export default ViewDiscussion;
