import {
  GET_DASHBOARD_LAST_REPORT_LIST,
  GET_DASHBOARD_ON_GOING_DISCUSSION_LIST,
  GET_DASHBOARD_DETAIL,
  REMOVE_ALL_DASHBOARD_LIST,
} from '../../utils/constants/constant';

const INIT_STATE = {
  lastUploadReportList: [],
  ongoingDiscussionList: [],
  detail: null,
};

const reducerFunc = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DASHBOARD_LAST_REPORT_LIST: {
      return {
        ...state,
        lastUploadReport: action.payload,
      };
    }
    case GET_DASHBOARD_ON_GOING_DISCUSSION_LIST: {
      return {
        ...state,
        ongoingDiscussionList: action.payload,
      };
    }
    case GET_DASHBOARD_DETAIL: {
      return {
        ...state,
        detail: action.payload,
      };
    }
    case REMOVE_ALL_DASHBOARD_LIST: {
      return {
        ...INIT_STATE,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducerFunc;
