import {
  GET_PHASE_LIST,
  REMOVE_PHASE_LIST,
} from '../../utils/constants/constant';

const INIT_STATE = {
  phaseListing: [],
};

const reducerFunc = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PHASE_LIST: {
      return {
        ...state,
        phaseListing: action.payload,
      };
    }
    case REMOVE_PHASE_LIST: {
      return {
        ...INIT_STATE,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducerFunc;
