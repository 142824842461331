import { FEEDBACK_BUTTONS } from 'app/utils/constants/feedbackBtn';

export const getStep = (progress) => {
  switch (true) {
    case progress >= 25 && progress < 50:
      return 1;
    case progress >= 50 && progress < 75:
      return 2;
    case progress >= 75 && progress < 99.99:
      return 3;
    case progress > 99.99:
      return 4;
    default:
      return;
  }
};
export const listStage = (progress) => {
  const stage = FEEDBACK_BUTTONS.filter((rec) => rec.percentage <= progress);
  return stage;
};
