export const SET_USER_INFORMATION = 'SET_USER_INFORMATION';
export const REMOVE_USER_INFORMATION = 'REMOVE_USER_INFORMATION';

// Project
export const STORE_PROJECT_ID = 'STORE_PROJECT_ID';
export const REMOVE_PROJECT_LIST = 'REMOVE_PROJECT_LIST';

//Report
export const GET_REPORT_TYPE_LIST = 'GET_REPORT_TYPE_LIST';
export const GET_REPORTS_LIST = 'GET_REPORTS_LIST';
export const GET_OLD_REPORTS_LIST = 'GET_OLD_REPORTS_LIST';
export const UPDATE_REPORTS_LIST = 'UPDATE_REPORTS_LIST';
export const UPDATE_OLD_REPORTS_LIST = 'UPDATE_OLD_REPORTS_LIST';
export const REMOVE_REPORTS_LIST = 'REMOVE_REPORTS_LIST';

//Phase
export const GET_PHASE_LIST = 'GET_PHASE_LIST';
export const REMOVE_PHASE_LIST = 'REMOVE_PHASE_LIST';

//Work schedule
export const GET_WORK_SCHEDULE_TOTAL_PROGRESS =
  'GET_WORK_SCHEDULE_TOTAL_PROGRESS';
export const GET_WORK_SCHEDULE_CHART_DATA = 'GET_WORK_SCHEDULE_CHART_DATA';
export const GET_WORK_SCHEDULE_PROGRESS_BY_PHASE =
  'GET_WORK_SCHEDULE_PROGRESS_BY_PHASE';
export const REMOVE_WORK_SCHEDULE_DATA = 'REMOVE_WORK_SCHEDULE_DATA';

//Discussion
export const GET_DISCUSSION_LIST = 'GET_DISCUSSION_LIST';
export const VIEW_DISCUSSION_LIST = 'VIEW_DISCUSSION_LIST';
export const REMOVE_DISCUSSION_LIST = 'REMOVE_DISCUSSION_LIST';

//Feedback
export const GET_FEEDBACK_LIST = 'GET_FEEDBACK_LIST';
export const REMOVE_FEEDBACK_LIST = 'REMOVE_FEEDBACK_LIST';

//DASHBOARD
export const GET_DASHBOARD_LAST_REPORT_LIST = 'GET_DASHBOARD_LAST_REPORT_LIST';
export const GET_DASHBOARD_ON_GOING_DISCUSSION_LIST =
  'GET_DASHBOARD_ON_GOING_DISCUSSION_LIST';
export const GET_DASHBOARD_DETAIL = 'GET_DASHBOARD_DETAIL';
export const REMOVE_ALL_DASHBOARD_LIST = 'REMOVE_ALL_DASHBOARD_LIST';

//Notification
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const GET_UNREAD_NOTIFICATION = 'GET_UNREAD_NOTIFICATION';
