import React from 'react';
import moment from 'moment';

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { useIsMobileDevice } from 'app/hooks/isMobile';
import Div from '@jumbo/shared/Div/Div';

const useStyles = makeStyles((theme) => ({
  projectText: {
    marginBottom: 10,
  },
  textCenter: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      padding: '15px',
    },
  },
  contentDays: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  days: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
      marginBottom: 10,
    },
  },
  numbers: {
    borderRadius: '50%',
    backgroundColor: 'white',
    height: '30px',
    width: '30px',
    textAlign: 'center',
    lineHeight: '30px',
    position: 'relative',
    marginRight: '15px',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '50%',
      right: '-25px',
      width: '25px',
      height: '2px',
      backgroundColor: theme.palette.text.white,
    },
    '&:last-child::after': {
      display: 'none',
    },
  },
}));

const ProjectCompletionDate = ({ date, days, isExceed }) => {
  const classes = useStyles();
  const { isMobile } = useIsMobileDevice();
  const day = days?.toString()?.split('');
  return (
    <Div className={classes.textCenter}>
      {!isMobile && (
        <Typography
          variant={'body1'}
          color="common.white"
          className={classes.projectText}
        >
          Project Completion Date
        </Typography>
      )}
      <Typography
        variant={'body1'}
        color="common.white"
        className={classes.projectText}
      >
        {moment(date).format('MMMM-DD-YYYY')}
      </Typography>
      <Div className={classes.contentDays}>
        <Div className={classes.days}>
          {day?.map((singleDay) => (
            <Typography
              sx={{ color: isExceed ? 'error.main' : 'text.black' }}
              key={singleDay}
              variant={'body1'}
              className={classes.numbers}
            >
              {singleDay}
            </Typography>
          ))}
        </Div>
        <Typography variant={'body1'} color={'text.white'}>
          Days to go
        </Typography>
      </Div>
    </Div>
  );
};
export default ProjectCompletionDate;
