import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { resetPassword } from 'app/redux/actions/auth';
import { passwordPatten } from '@jumbo/utils/validation';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { ROUTES } from 'app/utils/constants/routes';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { useIsTabDevice } from 'app/hooks/isMobile';
import { getAssetPath } from '../../../app/utils/appHelpers';
import Div from '@jumbo/shared/Div';
import JumboButton from '@jumbo/components/JumboButton/JumboButton';
import Logo from 'app/shared/Logo/Logo';
import JumboAppPasswordTextField from '@jumbo/components/JumboAppTextField/JumboAppPasswordTextField';
import CardComponent from '@jumbo/components/common/Card';
import Footer from 'app/layouts/shared/footers/Footer';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: 24,
    '&:last-child': {
      paddingBottom: '18px',
    },
  },
  mainContainer: {
    position: 'relative',
    display: 'block',
    height: '100vh',
    background: `url(${getAssetPath(
      `${ASSET_IMAGES}/Pidilite-Disha.jpg`,
    )}) no-repeat center`,
    backgroundSize: 'cover',
  },
  logoContainer: {
    position: 'relative',
    textAlign: 'right',
    right: '50px',
    top: '50px',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '-22px',
      right: '-25px',
      width: '50px',
      height: '50px',
      background: `url(${getAssetPath(
        `${ASSET_IMAGES}/Pidilite-Disha-logo.png`,
      )}) no-repeat center`,
    },
  },
  fontSize: {
    fontSize: '30px',
    color: theme.palette.info.main,
  },
  resetForm: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    textAlign: 'center',
    padding: '40px',
  },
  brandImage: {
    width: '200px',
    margin: '0 auto 40px',
  },
  submitBtn: {
    width: '100%',
    marginTop: 16,
  },
  footer: {
    width: '100%',
  },
}));
const ResetPassword = ({ disableSmLogin }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const [email] = useState(queryParams.get('email'));
  const [content] = useState(queryParams.get('content'));
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['access_token']);
  const [errorText, setErrorText] = useState({});
  const [value, setValue] = useState({
    newPassword: '',
    confirmPassword: '',
    pathname: location.pathname,
  });
  const { isTabDevice } = useIsTabDevice();
  const validate = () => {
    const errors = {};
    let isError = false;
    if (!value.newPassword) {
      errors.newPassword = 'Please enter password.';
      isError = true;
    } else if (passwordPatten(value?.newPassword)) {
      errors.newPassword =
        'Your password needs to be at least 8 characters, contain an uppercase letter, a lowercase letter, a number and one of the following symbols: ! @ £ $ %.';
      isError = true;
    }
    if (!value.confirmPassword) {
      errors.confirmPassword = 'Please enter confirm password.';
      isError = true;
    } else if (value.confirmPassword !== value.newPassword) {
      errors.confirmPassword = 'Password and confirm password must be same.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  const onResetPassword = async () => {
    let validateData = validate();
    if (!validateData.isError) {
      const response = await dispatch(
        resetPassword({
          ...value,
          email: {
            iv: email,
            content,
          },
        }),
      );
      if (response.status === 200) {
        enqueueSnackbar(response.data.responseMessage, {
          variant: 'success',
        });
        navigate(ROUTES.LOGIN);
      } else {
        enqueueSnackbar(response.error, {
          variant: 'error',
        });
      }
    }
  };

  useEffect(() => {}, []);

  return (
    <>
      <Grid container rowSpacing={5} direction="row" className="login-wrapper">
        {!isTabDevice && (
          <Grid item lg={8} xl={8} md={12} xs={12}>
            <Div className={classes.mainContainer}>
              <Div className={classes.logoContainer}>
                <Typography className={classes.fontSize} variant={'body1'}>
                  BUILDING TRUST
                </Typography>
                <Typography className={classes.fontSize} variant={'body1'}>
                  WITH TRANSPARENCY
                </Typography>
              </Div>
            </Div>
          </Grid>
        )}
        <Grid
          item
          xl={4}
          md={12}
          xs={12}
          lg={4}
          className={classes.backGroundImageContainer}
        >
          <Div className={classes.resetForm}>
            <Logo className={classes.brandImage} src="disha_logo.png" />
            <CardComponent
              title="Reset Password"
              className={classes.cardContent}
            >
              <JumboAppPasswordTextField
                label="Password*"
                name="newPassword"
                size="normal"
                margin="normal"
                onChange={(event) =>
                  setValue({ ...value, newPassword: event.target.value })
                }
                error={errorText.newPassword}
                helperText={errorText.newPassword}
              />
              <JumboAppPasswordTextField
                label="Confirm Password*"
                name="confirmPassword"
                size="normal"
                margin="normal"
                onChange={(event) =>
                  setValue({ ...value, confirmPassword: event.target.value })
                }
                error={errorText.confirmPassword}
                helperText={errorText.confirmPassword}
              />
              <JumboButton
                className={classes.submitBtn}
                color="primary"
                variant="contained"
                size="medium"
                onClick={onResetPassword}
              >
                Submit
              </JumboButton>
            </CardComponent>
          </Div>
        </Grid>
      </Grid>
      <Footer className={classes.footer} />
    </>
  );
};

export default ResetPassword;
