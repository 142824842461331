import { USE_IMAGE_PLACEHOLDERS } from './constants/paths';
import { ROUTES, TITLES_BY_ROUTE } from './constants/routes';

export const getAssetPath = (url, size) => {
  if (USE_IMAGE_PLACEHOLDERS) {
    return `https://via.placeholder.com/${size}.png`;
  }

  return url;
};

export const getTitle = (route) => {
  const key = Object.keys(ROUTES).find((k) => {
    const regex = new RegExp(`^${ROUTES[k].replace(/:\w+/g, '\\w+')}\\/?$`);
    return regex.test(route);
  });
  return key ? TITLES_BY_ROUTE[key] : '';
};
