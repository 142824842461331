import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export const downloadChart = async (e) => {
  let input = window.document.getElementsByClassName('div2PDF')[0];
  // const canvas = await html2canvas(input);
  // const data = canvas.toDataURL('image/png');
  // const pdf = new jsPDF('l', 'pt');
  // const imgProperties = pdf.getImageProperties(data);
  // const pdfWidth = pdf.internal.pageSize.getWidth() - 50;
  // const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  // pdf.addImage(data, 'PNG', 25, 25, pdfWidth, pdfHeight);
  // pdf.save('gantt-chart.pdf');

  html2canvas(input).then((canvas) => {
    const imgData = canvas.toDataURL('image/png');
    const pdf = new jsPDF('p', 'mm', 'a4');
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    pdf.addImage(imgData, 'PNG', 0, 10, pdfWidth, pdfHeight);
    pdf.save('gantt-chart.pdf');
  });
};
