import React from 'react';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@mui/styles';
import { IconButton } from '@mui/material';
import { useIsMobileDevice } from 'app/hooks/isMobile';
import { getTitle } from 'app/utils/appHelpers';
import { SIDEBAR_STYLES, SIDEBAR_VARIANTS } from '@jumbo/utils/constants';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Stack from '@mui/material/Stack';
import useJumboLayoutSidebar from '@jumbo/hooks/useJumboLayoutSidebar';
import Logo from 'app/shared/Logo/Logo';
import useJumboHeaderTheme from '@jumbo/hooks/useJumboHeaderTheme';
import AuthUserDropdown from '../../AuthUserDropdown';
import PageHeader from '../PageHeader/PageHeader';
import NotificationsDropdown from 'app/shared/NotificationsDropdown/NotificationsDropdown';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '110px',
  },
}));
const Header = () => {
  const classes = useStyles();
  const location = useLocation();
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const { headerTheme } = useJumboHeaderTheme();
  const { isMobile } = useIsMobileDevice();

  return (
    <React.Fragment>
      {(sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
        sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY ||
        (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT &&
          !sidebarOptions.open)) && (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          sx={{
            ml:
              sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                ? -2
                : 0,
            mr: 3,
          }}
          onClick={() =>
            setSidebarOptions({
              open: !sidebarOptions.open,
              hide: sidebarOptions.open,
            })
          }
        >
          {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
        </IconButton>
      )}
      {sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && (
        <Logo
          src={`mobile-logo-disha.png`}
          sx={{ mr: 3 }}
          mode={headerTheme.type ?? 'light'}
          className={classes.root}
        />
      )}
      {!isMobile && <PageHeader title={getTitle(location?.pathname)} />}
      <Stack
        direction="row"
        alignItems="center"
        spacing={1.25}
        sx={{ ml: 'auto' }}
      >
        <NotificationsDropdown />
        <AuthUserDropdown />
      </Stack>
    </React.Fragment>
  );
};

export default Header;
