import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TextField from '@mui/material/TextField';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';

const JumboAppPasswordTextField = ({
  type,
  name,
  id,
  fullWidth,
  value,
  onChange,
  helperText,
  variant,
  error,
  size,
  InputLabelProps,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      name={name}
      id={id || name}
      size={size}
      fullWidth={fullWidth}
      value={value}
      variant={variant}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      onChange={onChange}
      error={error || helperText !== ''}
      helperText={helperText}
      InputLabelProps={InputLabelProps}
      {...rest}
    />
  );
};
JumboAppPasswordTextField.prototype = {
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
};

JumboAppPasswordTextField.defaultProps = {
  type: 'password',
  fullWidth: true,
  size: 'small',
  error: false,
  helperText: '',
  variant: 'outlined',
};

export default JumboAppPasswordTextField;
