import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { makeStyles } from '@mui/styles';
import { ROUTES, viewReportRoute } from 'app/utils/constants/routes';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Radio,
  Typography,
} from '@mui/material';
import Div from '@jumbo/shared/Div/Div';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import AppSelectBox from '@jumbo/components/JumboAppSelect';
import JumboButton from '@jumbo/components/JumboButton/JumboButton';
import CardComponent from '@jumbo/components/common/Card';
import Accordion from '@jumbo/components/common/Accordion';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import JumboDdMenu from '@jumbo/components/JumboDdMenu/JumboDdMenu';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FormDialog from '@jumbo/components/common/Dialog';
import JumboAppTextField from '@jumbo/components/JumboAppTextField/JumboAppTextField';
import { getTitle } from 'app/utils/appHelpers';
import { useIsMobileDevice } from 'app/hooks/isMobile';
import {
  getReportsType,
  getReports,
  addRemark,
  updateReportList,
  getReportsById,
} from 'app/redux/actions/report';
import { getPhase } from 'app/redux/actions/phase';
import Avatar from '@jumbo/components/common/Avatar';
import moment from 'moment/moment';

const useStyles = makeStyles((theme) => ({
  orText: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: ' center',
    [theme.breakpoints.down('md')]: {
      marginTop: '5px',
    },
  },
  root: {
    height: '51px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      height: '40px',
    },
  },
  cardContent: { padding: 24 },
  marginTop: {
    marginTop: 30,
  },
  avatarIcon: {
    width: 40,
    height: 40,
  },
}));

const Reports = () => {
  const { isMobile } = useIsMobileDevice();
  const { enqueueSnackbar } = useSnackbar();
  const { cardContent, root, marginTop, gridContent, orText, avatarIcon } =
    useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location?.search);
  const [queryReportId] = useState(queryParams.get('reportId'));
  const { reportType, reports } = useSelector(({ report }) => report);
  const { phaseListing } = useSelector(({ phase }) => phase);
  const { projectId } = useSelector(({ project }) => project);
  const [value, setValue] = useState({
    reportTypeId: '',
    phaseId: '',
    isGeneral: null,
  });
  const [errorText, setErrorText] = React.useState({});
  const [open, setOpen] = useState(false);
  const [remark, setRemark] = useState('');
  const [reportId, setReportId] = useState(null);
  const [remarkFlag, setRemarkFlag] = useState(false);
  const [type, setType] = useState();
  const navigate = useNavigate();
  const handleItemAction = (menuItem, record) => {
    // eslint-disable-next-line default-case
    switch (menuItem.action) {
      case 'addRemark':
        setErrorText({});
        setReportId(record?._id);
        setRemarkFlag(record?.remark ? true : false);
        if (record?.remark) {
          setRemark(record?.remark);
        } else {
          setRemark('');
        }
        setOpen(!open);
        break;
      case 'oldReport':
        navigate(
          viewReportRoute(
            record?._id,
            record?.category?._id,
            record?.phase?._id,
          ),
        );
        break;
      case 'downloadReport':
        window.open(record?.attachment, '_blank');
        break;
    }
  };
  const columns = [
    {
      label: ' ',
      key: 'icon',
      render: (elm) => (
        <>
          <Avatar
            className={avatarIcon}
            icon={
              elm?.attachmentExt === 'pdf'
                ? 'pdfImg.png'
                : elm?.attachmentExt === 'doc' || elm?.attachmentExt === 'docx'
                ? 'doc.png'
                : elm?.attachmentExt === 'xls' || elm?.attachmentExt === 'xlsx' || elm?.attachmentExt === 'csv'
                ? 'xls.png'
                : ''
            }
          />
        </>
      ),
    },
    {
      label: 'Name',
      key: 'name',
    },
    {
      label: 'Category',
      render: (elm) => <>{elm?.category?.name}</>,
    },
    {
      label: 'Phase Name',
      render: (elm) => <>{elm?.phase?.name ? elm?.phase?.name : 'Common'}</>,
    },
    {
      label: 'Uploaded On',
      key: 'createdAt',
      render: (elm) => <>{moment(elm?.createdAt).format('DD-MM-YYYY')}</>,
    },
    {
      label: 'Remarks',
      key: 'remark',
      ellipsis: true,
    },
    {
      label: 'Comment',
      key: 'description',
      ellipsis: true,
    },
    {
      label: ' ',
      key: 'actionIcon',
      render: (elm) => (
        <JumboDdMenu
          icon={<MoreHorizIcon />}
          menuItems={[
            {
              icon: <AddCircleOutlineIcon />,
              title: elm?.remark ? 'Edit Remark' : 'Add Remark',
              action: 'addRemark',
            },
            {
              icon: <AssignmentIcon />,
              title: 'Old Report',
              action: 'oldReport',
            },
            {
              icon: <VisibilityIcon />,
              title: 'View Report',
              action: 'downloadReport',
            },
          ]}
          onClickCallback={(event) => handleItemAction(event, elm)}
        />
      ),
    },
  ];
  const handleChange = (event) => {
    setRemark(event.target.value);
  };
  const getReportList = (isShowAll) => {
    const searchValue = { projectId };
    if (!isShowAll) {
      searchValue.categoryId = value?.reportTypeId;
      searchValue.phaseId = value?.phaseId;
      if (value?.isGeneral) searchValue.isGeneral = value?.isGeneral;
    } else {
      setValue({ reportTypeId: '', phaseId: '', isGeneral: false });
    }
    dispatch(getReports(searchValue));
  };

  const handleSubmit = async () => {
    let validateData = validate();
    if (!validateData.isError) {
      const response = await dispatch(addRemark({ reportId, remark }));
      if (response.status === 200) {
        const { remarkAdd } = response.data.data;
        const updateReport = reports.map((single) =>
          single?._id === reportId ? remarkAdd : single,
        );
        enqueueSnackbar(response.data.responseMessage, {
          variant: 'success',
        });
        dispatch(updateReportList(updateReport));
        setOpen(!open);
      } else {
        enqueueSnackbar(response.error, {
          variant: 'error',
        });
      }
    }
  };
  const validate = () => {
    const errors = {};
    let isError = false;
    if (!remark) {
      errors.remark = 'Please enter remark.';
      isError = true;
    } else if (!(remark.length > 4 && remark.length < 200)) {
      errors.remark = 'Remark can set of between 5 and 200 characters!';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  useEffect(() => {
    if (queryReportId) {
      dispatch(getReportsById(queryReportId));
    } else {
      getReportList();
    }
    dispatch(getReportsType());
    dispatch(getPhase(projectId));
  }, []);
  return (
    <>
      {isMobile ? <PageHeader title={getTitle(ROUTES.REPORTS)} /> : ''}
      <Card>
        <CardHeader title="Search Report" />
        <CardContent className={cardContent}>
          <Grid
            container
            columnSpacing={{ lg: 4, md: 4 }}
            rowSpacing={1}
            className={gridContent}
            direction="row"
          >
            <Grid item xs={12} md={12} lg={12}>
              <Radio
                checked={type === 'phase'}
                onChange={(event) => {
                  setType(event.target.value);
                  setValue({ ...value, isGeneral: false });
                }}
                value="phase"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'A' }}
                className="py-sm-0"
              />
              Phase Wise
              <Radio
                checked={type === 'general'}
                onChange={(event) => {
                  setType(event.target.value);
                  setValue({ ...value, isGeneral: true, phaseId: '' });
                }}
                value="general"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'A' }}
                className="py-sm-0 pl-sm-10"
              />
              Common
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <AppSelectBox
                required
                data={reportType}
                valueKey="_id"
                labelKey="name"
                label="Select Report Type"
                value={value?.reportTypeId}
                variant="outlined"
                onChange={(event) =>
                  setValue({ ...value, reportTypeId: event.target.value })
                }
              />
            </Grid>
            {type === 'phase' && (
              <Grid item xs={12} md={3} lg={4}>
                <AppSelectBox
                  required
                  data={phaseListing}
                  valueKey="_id"
                  labelKey="name"
                  label="Select Phase"
                  value={value?.phaseId}
                  variant="outlined"
                  onChange={(event) =>
                    setValue({ ...value, phaseId: event.target.value })
                  }
                />
              </Grid>
            )}
            <Grid item xs={12} md={5} lg={4}>
              <Grid container>
                <Grid item xs={12} md={5} lg={5}>
                  <JumboButton
                    className={root}
                    variant="contained"
                    size="medium"
                    color="primary"
                    onClick={() => getReportList()}
                  >
                    Search
                  </JumboButton>
                </Grid>
                <Grid item xs={12} md={2} lg={2} className={orText}>
                  <Typography variant="h6">OR</Typography>
                </Grid>

                <Grid item xs={12} md={5} lg={5}>
                  <JumboButton
                    className={root}
                    variant="contained"
                    size="medium"
                    color="search"
                    onClick={() => {
                      getReportList('isShowAll');
                      setType(null);
                    }}
                  >
                    Show All
                  </JumboButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Div className={marginTop}>
        <CardComponent title="Reports List">
          <Accordion
            columns={columns}
            record={reports}
            mobileTitleKey={['name']}
          />
        </CardComponent>
      </Div>
      <Div>
        <FormDialog
          open={open}
          maxWidth={'md'}
          title={remarkFlag ? 'Edit Remark' : 'Add Remark'}
          handleClose={() => {
            setRemark('');
            setOpen(!open);
          }}
          handleSubmit={() => handleSubmit()}
          confirmButtonText="Submit"
          cancelButtonText="Cancel"
        >
          <JumboAppTextField
            multiline
            rows={4}
            label="Remark*"
            name="remark"
            onChange={handleChange}
            value={remark}
            error={errorText.remark}
            helperText={errorText.remark}
          />
        </FormDialog>
      </Div>
    </>
  );
};
export default Reports;
