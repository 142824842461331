import axios from 'axios';

import {
  STORE_PROJECT_ID,
  REMOVE_PROJECT_LIST,
} from '../../utils/constants/constant';

export const getProjectList = () => {
  return (dispatch, getState) => {
    return axios
      .get(`project/list`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const getProjectTeam = (id) => {
  return (dispatch, getState) => {
    return axios
      .get(`project/${id}`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const storeProjectId = (projectId) => {
  return (dispatch) => {
    dispatch({
      type: STORE_PROJECT_ID,
      payload: projectId,
    });
  };
};

export const removeProjectData = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_PROJECT_LIST,
    });
  };
};
