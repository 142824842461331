import React from 'react';

import { makeStyles } from '@mui/styles';
import { useIsMobileDevice } from 'app/hooks/isMobile';
import { Box, Typography } from '@mui/material';

import Div from '@jumbo/shared/Div/Div';
import Logo from 'app/shared/Logo/Logo';
import LinearProgress from '../common/LinearProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  relative: {
    position: 'relative',
    width: '80%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: '15px',
    },
  },
  overallText: {
    marginBottom: 10,
  },
  linearProgress: {
    width: '90%',
    borderRadius: 4,
    height: 10,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '85%',
    },
  },
}));

const OverallProject = ({ progress }) => {
  const classes = useStyles();
  const { isMobile } = useIsMobileDevice();
  return (
    <Box>
      {!isMobile && (
        <Typography variant={'body1'} className={classes.overallText}>
          Overall Projects Status
        </Typography>
      )}
      <Div className={classes.relative}>
        <Logo src={`overall_project.png`} className={classes.root} />
        <LinearProgress className={classes.linearProgress} value={progress} />
      </Div>
    </Box>
  );
};
export default OverallProject;
