import axios from 'axios';

import {
  GET_PHASE_LIST,
  REMOVE_PHASE_LIST,
} from '../../utils/constants/constant';

export const getPhase = (projectId) => {
  return (dispatch, getState) => {
    return axios
      .get(`work-schedule/phase/list`, { params: { projectId } })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_PHASE_LIST,
            payload: response?.data?.data?.phaseList,
          });
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const removePhaseData = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_PHASE_LIST,
    });
  };
};
