import axios from 'axios';

import {
  GET_FEEDBACK_LIST,
  REMOVE_FEEDBACK_LIST,
} from '../../utils/constants/constant';

export const getFeedbackList = (data) => {
  return (dispatch, getState) => {
    return axios
      .get(`feedback/list`, { params: { ...data } })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_FEEDBACK_LIST,
            payload: response?.data?.data?.discussionTopicList,
          });
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const createFeedback = (data) => {
  return (dispatch, getState) => {
    return axios
      .post(`feedback`, data)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMessage });
      });
  };
};

export const removeFeedbackData = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_FEEDBACK_LIST,
    });
  };
};
