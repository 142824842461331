import React from 'react';
import { Typography } from '@mui/material';
import Div from '@jumbo/shared/Div';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    fontSize: theme.typography.h2.fontSize,
    margin: 0,
  },
}));

const PageHeader = ({ title }) => {
  const classes = useStyles();

  return (
    <Div className={classes.root}>
      <Typography variant={'h2'} className={classes.title}>
        {`${title} - ${JSON.parse(localStorage.getItem('PROJECT_NAME'))}`}
      </Typography>
    </Div>
  );
};

export default PageHeader;
