import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import userInformation from './auth';
import project from './project';
import report from './report';
import phase from './phase';
import workSchedule from './workSchedule';
import discussion from './discussion';
import feedback from './feedback';
import dashboard from './dashboard';
import notification from './notification';

const exportReducers = (history) => {
  return combineReducers({
    router: connectRouter(history),
    userInformation,
    project,
    report,
    phase,
    workSchedule,
    discussion,
    feedback,
    dashboard,
    notification,
  });
};

export default exportReducers;
