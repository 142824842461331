import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';

import { signIn } from 'app/redux/actions/auth';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';
import { storeProjectId } from 'app/redux/actions/project';
import { ROUTES } from 'app/utils/constants/routes';
import { ASSET_IMAGES } from 'app/utils/constants/paths';
import { useIsTabDevice } from 'app/hooks/isMobile';
import { getAssetPath } from '../../../app/utils/appHelpers';
import { contactDetail } from 'app/utils/constants/conatctDeatil';
import { emailPatten } from '@jumbo/utils/validation';
import JumboTextField from '@jumbo/components/JumboAppTextField';
import Div from '@jumbo/shared/Div';
import JumboButton from '@jumbo/components/JumboButton/JumboButton';
import Logo from 'app/shared/Logo/Logo';
import JumboIconButton from '@jumbo/components/JumboIconButton/JumboIconButton';
import EmailIcon from '@mui/icons-material/Email';
import JumboAppPasswordTextField from '@jumbo/components/JumboAppTextField/JumboAppPasswordTextField';
import CardComponent from '@jumbo/components/common/Card';
import Footer from 'app/layouts/shared/footers/Footer';
// import { requestForToken } from '../../../firebase';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: 18,
    marginTop: 10,
    marginBottom: 10,
    '&:last-child': {
      paddingBottom: '18px',
    },
  },
  mainContainer: {
    position: 'relative',
    display: 'block',
    height: '100vh',
    background: `url(${getAssetPath(
      `${ASSET_IMAGES}/Pidilite-Disha.jpg`,
    )}) no-repeat center`,
    backgroundSize: 'cover',
  },
  marginTop18: {
    marginTop: 22,
  },
  loginForm: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    textAlign: 'center',
    padding: '40px',
    [theme.breakpoints.down('sm')]: {
      padding: '20px',
    },
  },
  brandImage: {
    width: '150px',
    margin: '0 auto 20px',
  },

  marginTop: {
    marginTop: 25,
  },
  icons: {
    backgroundColor: theme.palette.background.icon,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      width: 25,
      height: 25,
    },
  },
  marginTop20: {
    marginTop: 20,
  },
  phoneContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    justifyContent: 'center',
  },
  marginEmailIcon: {
    marginRight: 15,
  },
  dayMargin: {
    marginTop: '35px',
  },
  storeIcon: {
    width: '125px',
  },
  storeImageContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 18,
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  marginLeft: {
    marginLeft: 10,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  w50: {
    width: '50%',
    paddingLeft: '5px',
    paddingRight: '5px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '10px',
    },
  },
  password: {
    marginTop: '15px',
    width: '50%',
    paddingLeft: '5px',
    paddingRight: '5px',
    display: 'inline-block',
    verticalAlign: 'text-top',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: '10px',
    },
  },
  iconsvg: {
    [theme.breakpoints.down('sm')]: {
      width: 15,
      height: 15,
    },
  },
  submitbtn: {
    width: '100%',
    height: '35px',
  },
  logoContainer: {
    position: 'relative',
    textAlign: 'right',
    right: '50px',
    top: '50px',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '-22px',
      right: '-25px',
      width: '50px',
      height: '50px',
      background: `url(${getAssetPath(
        `${ASSET_IMAGES}/Pidilite-Disha-logo.png`,
      )}) no-repeat center`,
    },
  },
  fontSize: {
    fontSize: '30px',
    color: theme.palette.info.main,
  },
  forgetPassword: {
    marginBottom: 0,
    marginTop: 9,
  },
  forgetPasswordLink: {
    color: theme.palette.error.main,
  },
  footer: {
    width: '100%',
  },
}));
const Login = ({ disableSmLogin }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies(['access_token']);
  const [errorText, setErrorText] = useState({});
  const [value, setValue] = useState({
    email: '',
    password: '',
    projectId: '',
  });
  const { isTabDevice } = useIsTabDevice();
  const validate = () => {
    const errors = {};
    let isError = false;
    if (!value.email) {
      errors.email = 'Please enter email.';
      isError = true;
    } else if (emailPatten(value.email)) {
      errors.email = 'Invalid email address.';
      isError = true;
    }
    if (!value.password) {
      errors.password = 'Please enter password.';
      isError = true;
    }
    if (!value.projectId) {
      errors.projectId = 'Please enter project id.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  const onSignIn = async () => {
    let validateData = validate();
    if (!validateData.isError) {
      const authValue = {
        email: value?.email,
        password: value?.password,
        projectId: value?.projectId?.trim().toUpperCase(),
        deviceToken: JSON.parse(localStorage.getItem('FCM_TOKEN')),
      };
      const response = await dispatch(signIn(authValue));
      if (response.status === 200) {
        setCookie('access_token', response?.data?.data?.token, {
          path: '/',
          secure: false,
          // httpOnly: true,
          sameSite: 'strict',
        });
        dispatch(storeProjectId(response?.data?.data?.user?.projectId));
        localStorage.setItem(
          'PROJECT_NAME',
          JSON.stringify(response?.data?.data?.user?.projectName),
        );

        navigate(ROUTES.DASHBOARD);
      } else {
        enqueueSnackbar(response.error, {
          variant: 'error',
        });
      }
    }
  };
  // useEffect(() => {
  //   requestForToken();
  // }, []);
  const content = (
    <>
      <CardComponent title="Login" className={classes.cardContent}>
        <JumboTextField
          className={classes.w50}
          type="text"
          label="Project Id*"
          name="projectId"
          size="small"
          onChange={(event) =>
            setValue({ ...value, projectId: event.target.value })
          }
          error={errorText.projectId}
          helperText={errorText.projectId}
        />
        <JumboTextField
          className={classes.w50}
          type="text"
          label="Email*"
          name="email"
          size="small"
          onChange={(event) =>
            setValue({ ...value, email: event.target.value })
          }
          error={errorText.email}
          helperText={errorText.email}
        />
        <Div className={classes.password}>
          <JumboAppPasswordTextField
            name="password"
            label="Password*"
            size="small"
            onChange={(event) =>
              setValue({ ...value, password: event.target.value })
            }
            value={value.password}
            error={errorText.password}
            helperText={errorText.password}
          />
          <Typography
            textAlign={'left'}
            variant={'body1'}
            className={classes.forgetPassword}
          >
            <Link
              className={classes.forgetPasswordLink}
              to={ROUTES.FORGETPASSWORD}
            >
              Forgot password?
            </Link>
          </Typography>
        </Div>
        <Div className={classes.password}>
          <JumboButton
            className={classes.submitbtn}
            color="primary"
            variant="contained"
            size="small"
            onClick={onSignIn}
          >
            Enter
          </JumboButton>
        </Div>
      </CardComponent>
      <Div>
        <Typography
          className={classes.dayMargin}
          variant={'h5'}
          color="#979797"
        >
          {contactDetail.workingDay}
        </Typography>
        <Div className={`${classes.phoneContainer} ${classes.marginEmailIcon}`}>
          <JumboIconButton className={classes.icons} elevation={25}>
            <EmailIcon className={classes.iconsvg} sx={{ fontSize: 20 }} />
          </JumboIconButton>
          <Typography color="#979797" variant="body1">
            {contactDetail.email}
          </Typography>
        </Div>
      </Div>
      {/* <Typography className={classes.marginTop} variant={'h2'} color="#979797">
        Get our mobile app at
      </Typography>
      <Div className={classes.storeImageContainer}>
        <Logo className={classes.storeIcon} src={'google_play.png'} />
        <Logo
          className={`${classes.storeIcon} ${classes.marginLeft}`}
          src={'apple_store.png'}
        />
      </Div> */}
    </>
  );
  return (
    <>
      <Grid container rowSpacing={5} direction="row" className="login-wrapper">
        {!isTabDevice && (
          <Grid item lg={8} xl={8} md={12} xs={12}>
            <Div className={classes.mainContainer}>
              <Div className={classes.logoContainer}>
                <Typography className={classes.fontSize} variant={'body1'}>
                  BUILDING TRUST
                </Typography>
                <Typography className={classes.fontSize} variant={'body1'}>
                  WITH TRANSPARENCY
                </Typography>
              </Div>
            </Div>
          </Grid>
        )}
        <Grid item xl={4} md={12} xs={12} lg={4}>
          <Div className={classes.loginForm}>
            <Logo className={classes.brandImage} src="disha_logo.png" />
            {content}
            {/* {isTabDevice || isLargeDevice ? (
              <Div>{content}</Div>
            ) : (
              <>{content}</>
            )} */}
          </Div>
        </Grid>
      </Grid>
      <Footer className={classes.footer} />
    </>
  );
};

export default Login;
