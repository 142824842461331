import axios from 'axios';

import {
  GET_REPORT_TYPE_LIST,
  GET_REPORTS_LIST,
  GET_OLD_REPORTS_LIST,
  UPDATE_REPORTS_LIST,
  UPDATE_OLD_REPORTS_LIST,
  REMOVE_REPORTS_LIST,
} from '../../utils/constants/constant';

export const getReportsType = () => {
  return (dispatch, getState) => {
    return axios
      .get(`report/type`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_REPORT_TYPE_LIST,
            payload: response?.data?.data?.reportCategoryList,
          });
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const getReports = (data) => {
  return (dispatch, getState) => {
    return axios
      .get(`report/list`, { params: { ...data } })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_REPORTS_LIST,
            payload: response?.data?.data?.reportList,
          });
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const getReportsById = (id) => {
  return (dispatch, getState) => {
    return axios
      .get(`report/get-by-id/${id}`)
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_REPORTS_LIST,
            payload: [response?.data?.data?.findExistingReport],
          });
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const addRemark = (data) => {
  return (dispatch, getState) => {
    return axios
      .put(`report/${data?.reportId}`, data)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMessage });
      });
  };
};
export const updateReportList = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_REPORTS_LIST,
      payload: data,
    });
  };
};
export const getOldReports = (data) => {
  return (dispatch, getState) => {
    return axios
      .get(`report/list-old`, { params: { ...data } })
      .then((response) => {
        if (response.status === 200) {
          dispatch({
            type: GET_OLD_REPORTS_LIST,
            payload: response?.data?.data?.oldReportsList,
          });
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const updateOldReportList = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_OLD_REPORTS_LIST,
      payload: data,
    });
  };
};

export const removeReportData = () => {
  return (dispatch) => {
    dispatch({
      type: REMOVE_REPORTS_LIST,
    });
  };
};
