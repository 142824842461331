import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

import { useIsMobileDevice } from 'app/hooks/isMobile';
import { getTitle } from 'app/utils/appHelpers';
import {
  ROUTES,
  TITLES_BY_ROUTE,
  viewDiscussionRoute,
} from 'app/utils/constants/routes';
import { makeStyles } from '@mui/styles';
import ReplyIcon from '@mui/icons-material/Reply';
import { Grid, Typography } from '@mui/material';
import { Visibility } from '@mui/icons-material';
import {
  getDiscussionTopicList,
  saveDiscussion,
  getDiscussionList,
} from 'app/redux/actions/discussion';
import { uploadFileS3, uploadFileS3signedUrl } from 'app/redux/actions/aws';
import Div from '@jumbo/shared/Div/Div';
import PageHeader from 'app/layouts/shared/headers/PageHeader/PageHeader';
import Card from '@jumbo/components/common/Card';
import AppSelectBox from '@jumbo/components/JumboAppSelect';
import JumboAppTextField from '@jumbo/components/JumboAppTextField/JumboAppTextField';
import JumboButton from '@jumbo/components/JumboButton/JumboButton';
import Accordion from '@jumbo/components/common/Accordion';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import JumboDdMenu from '@jumbo/components/JumboDdMenu/JumboDdMenu';
import BasicChips from '@jumbo/components/common/Chip';
import DropzoneComponent from '@jumbo/components/common/Dropzone';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: 24,
    '&:last-child': {
      paddingBottom: '18px',
    },
  },
  textFieldMargin: {
    marginTop: 22,
  },
  buttonAction: {
    display: 'flex',
    marginTop: '24px',
  },
  marginTop: {
    marginTop: 30,
  },
  marginLeft: {
    marginLeft: 5,
  },
  marginTop16: {
    marginTop: 16,
  },
}));

const Discussion = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { discussionList } = useSelector(({ discussion }) => discussion);
  const { projectId } = useSelector(({ project }) => project);
  const { isMobile } = useIsMobileDevice();
  const [value, setValue] = useState({
    topicId: '',
    ticketTitle: '',
    comment: '',
    attachment: '',
    previewAttachment: null,
  });
  const [topics, setTopics] = useState([]);
  const [topicsAll, setTopicsAll] = useState([]);
  const [selectedTab, setSelectedTab] = useState(null);
  const [errorText, setErrorText] = React.useState({});
  const handleItemAction = (menuItem, id) => {
    // eslint-disable-next-line default-case
    switch (menuItem.action) {
      case 'view':
        navigate(viewDiscussionRoute(id));
        break;
    }
  };
  const columns = [
    {
      label: 'Discussion No.',
      key: 'ticketNumber',
    },
    {
      label: 'Discussion Title',
      key: 'ticketTitle',
      ellipsis: true,
    },
    {
      label: 'Start Date',
      key: 'createdAt',
      render: (elm) => <>{moment(elm?.createdAt).format('DD-MM-YYYY')}</>,
    },
    {
      label: 'Status',
      key: 'status',
      render: (elm) => (
        <BasicChips
          label={elm?.status ? 'Open' : 'Closed'}
          variant={'Filled'}
          color={elm?.status ? 'search' : 'error'}
        />
      ),
    },
    {
      label: ' ',
      key: 'actionIcon',
      width: 100,
      render: (elm) => (
        <JumboDdMenu
          icon={<MoreHorizIcon />}
          menuItems={[
            {
              icon: <Visibility />,
              title: 'View',
              action: 'view',
            },
            {
              icon: <ReplyIcon />,
              title: 'Reply',
              action: 'view',
            },
          ]}
          onClickCallback={(event) => handleItemAction(event, elm?._id)}
        />
      ),
    },
  ];
  useEffect(() => {
    const getDiscussionTopics = async () => {
      const response = await dispatch(getDiscussionTopicList({ projectId }));
      if (response.status === 200) {
        const { discussionTopicList } = response.data.data;
        if (discussionTopicList?.length) {
          discussionListing(discussionTopicList?.[0]?._id);
          setSelectedTab(discussionTopicList?.[0]?._id);
          setTopics(discussionTopicList);
        }
      }
    };
    const getDiscussionTopicsAll = async () => {
      const response2 = await dispatch(getDiscussionTopicList());
      if (response2.status === 200) {
        const { discussionTopicList } = response2.data.data;
        if (discussionTopicList?.length) {
          setTopicsAll(discussionTopicList);
        }
      }
    };
    getDiscussionTopics();
    getDiscussionTopicsAll();
  }, []);
  const validate = () => {
    const errors = {};
    let isError = false;
    if (!value?.topicId) {
      errors.topicId = 'Please select Category id.';
      isError = true;
    }
    if (!value?.ticketTitle) {
      errors.ticketTitle = 'Please enter discussion title.';
      isError = true;
    }
    if (!value?.comment) {
      errors.comment = 'Please enter comment.';
      isError = true;
    }
    setErrorText(errors);
    return {
      errors,
      isError,
    };
  };
  const onSubmit = async () => {
    let validateData = validate();
    if (!validateData.isError) {
      value.projectId = projectId;
      const response = await dispatch(saveDiscussion(value));
      if (response.status === 200) {
        discussionListing(value?.topicId);
        setSelectedTab(value?.topicId);
        setValue({
          topicId: '',
          ticketTitle: '',
          comment: '',
          attachment: '',
          previewAttachment: null,
        });
        enqueueSnackbar(response.data.responseMessage, {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(response.error, {
          variant: 'error',
        });
      }
    }
  };
  const onCancel = () => {
    setValue({
      topicId: '',
      ticketTitle: '',
      comment: '',
      attachment: '',
      previewAttachment: null,
    });
    setErrorText({});
  };
  const onUpload = async (file) => {
    if (file[0] && file[0].size <= 2097152) {
      const response = await dispatch(
        uploadFileS3signedUrl({
          key: `${Date.now()}-${file[0].name.replace(' ', '')}`,
          ContentType: file[0].type,
          folderName: process.env.REACT_APP_FOLDER_NAME_DISCUSSION,
        }),
      );

      if (response.status === 200) {
        setValue({
          ...value,
          attachment: response?.data?.data?.url,
          previewAttachment: file[0]?.preview,
        });
        await dispatch(
          uploadFileS3({
            url: response.data.data.signedUrl,
            data: file[0],
          }),
        );
      } else {
        enqueueSnackbar(response.data?.responseMessage, {
          variant: 'error',
        });
      }
    } else {
      enqueueSnackbar('The document may not be greater than 2 MB', {
        variant: 'error',
      });
    }
  };
  const discussionListing = async (topicId) => {
    dispatch(getDiscussionList({ topicId, projectId }));
  };
  const handleTab = (topicId) => {
    setSelectedTab(topicId);
    discussionListing(topicId);
  };
  return (
    <>
      {isMobile && <PageHeader title={getTitle(ROUTES.DISCUSSION)} />}
      <Card
        title={TITLES_BY_ROUTE.LETSDISCUSSION}
        className={classes.cardContent}
      >
        <Grid container spacing={1} rowSpacing={3}>
          <Grid item lg={6} md={12} xs={12}>
            <AppSelectBox
              required
              data={topicsAll}
              valueKey="_id"
              labelKey="name"
              label="Select Category*"
              value={value?.topicId}
              variant="outlined"
              onChange={(event) =>
                setValue({ ...value, topicId: event.target.value })
              }
              error={errorText.topicId}
              helperText={errorText.topicId}
            />
          </Grid>
          <Grid item lg={6} md={12} xs={12}>
            <JumboAppTextField
              type="text"
              label="Discussion Title*"
              name="ticketTitle"
              size="normal"
              value={value?.ticketTitle}
              onChange={(event) =>
                setValue({ ...value, ticketTitle: event.target.value })
              }
              error={errorText.ticketTitle}
              helperText={errorText.ticketTitle}
            />
          </Grid>
          <Grid item lg={6} md={12} xs={12}>
            <Typography variant="body1">Attachment</Typography>
            <DropzoneComponent
              onDropFile={(files) => onUpload(files)}
              files={
                value?.attachment
                  ? [
                      {
                        preview: value?.previewAttachment,
                        fileName: value?.attachment,
                      },
                    ]
                  : []
              }
              onRemove={() =>
                setValue({
                  ...value,
                  attachment: null,
                  previewAttachment: null,
                })
              }
            />
          </Grid>

          <Grid item lg={6} md={12} xs={12}>
            <JumboAppTextField
              className={classes.textFieldMargin}
              type="text"
              label="Write your query/comment*"
              multiline
              rows={4}
              name="comment"
              value={value?.comment}
              onChange={(event) =>
                setValue({ ...value, comment: event.target.value })
              }
              error={errorText.comment}
              helperText={errorText.comment}
            />
          </Grid>
        </Grid>
        <Div className={classes.buttonAction}>
          <JumboButton
            color="primary"
            variant="contained"
            size="medium"
            onClick={onSubmit}
          >
            Submit
          </JumboButton>
          <JumboButton
            color="error"
            variant="contained"
            size="medium"
            className={classes.marginLeft}
            onClick={onCancel}
          >
            Cancel
          </JumboButton>
        </Div>
      </Card>
      <Div className={classes.marginTop}>
        <Card title={TITLES_BY_ROUTE.DISCUSSIONLIST}>
          <Grid
            className="discussion-threads-row"
            container
            spacing={1}
            rowSpacing={1}
            direction="row"
            pl={3}
            mt={2}
          >
            {topics?.length
              ? topics.map((singleTopic, index) => (
                  <Grid key={index} item xs="auto">
                    <JumboButton
                      color="search"
                      variant={
                        singleTopic?._id === selectedTab
                          ? 'contained'
                          : 'outlined'
                      }
                      size="small"
                      onClick={() => handleTab(singleTopic?._id)}
                    >
                      {singleTopic?.name}
                    </JumboButton>
                  </Grid>
                ))
              : ''}
          </Grid>
          <Div className={classes.marginTop16}>
            <Accordion
              columns={columns}
              record={discussionList}
              mobileTitleKey={['ticketNumber']}
            />
          </Div>
        </Card>
      </Div>
    </>
  );
};
export default Discussion;
