import React from 'react';

import { Card, CardHeader, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cardHeader: {
    background: `linear-gradient(180deg, #BDD52F 0%, rgba(210, 236, 58, 0.46) 100%)`,
    boxShadow: `0px 4px 8px rgba(0, 0, 0, 0.2)`,
    borderRadius: `10px 10px 0 0`,
    fontSize: `22px`,
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      padding: '18px 14px',
    },
  },
  cardLast: {
    '&:last-child': {
      paddingBottom: 0,
    },
  },
}));

const CardComponent = ({ title, children, className }) => {
  const { cardHeader, cardLast } = useStyles();
  return (
    <Card>
      {title && (
        <CardHeader color="common.black" className={cardHeader} title={title} />
      )}
      <CardContent className={`${cardLast} ${className}`}>
        {children}
      </CardContent>
    </Card>
  );
};

export default CardComponent;
